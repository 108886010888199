import React from "react";
import { Form, Outlet } from "react-router-dom";
import OrgaoSearchBox from "./OrgaoSearchBox";
import { useDispatch, useSelector } from "react-redux";
import { orgaoActions } from "src/store/orgao-slice";

const OrgaoEletronicoRootLayout = () => {
  const orgaoState = useSelector((state) => state.orgao);
  const dispatch = useDispatch();

  function submitHandler(event) {
    event.preventDefault();
    dispatch(orgaoActions.addSearchHistory({ term: orgaoState.term }));
  }

  return (
    <>
      <Form method="post" onSubmit={submitHandler}>
        <OrgaoSearchBox history={orgaoState.searchHistory} />
      </Form>
      <Outlet />
    </>
  );
};

export default OrgaoEletronicoRootLayout;

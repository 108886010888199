import { Button, Grid, LinearProgress, TableFooter, TablePagination, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Suspense, ChangeEvent } from "react";
import { Await, defer, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import store from "src/store";
import eventoHistoricoService from "src/Service/evento-historico-service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { prettyDateTime } from "src/common/util";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { eventoHistoricoActions } from "src/store/evento-historico-slice";

const EventoHistoricoGrid = () => {
  const navigation = useNavigation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventoHistoricoState = store.getState().eventoHistorico;
  const { eventos }: any = useLoaderData();

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(eventoHistoricoActions.setPage({ page: newPage }));
    navigate(
      `/policial/evento-historico?term=${eventoHistoricoState.term}&page=${newPage}&pageSize=${eventoHistoricoState.pageSize}&withError=${eventoHistoricoState.searchWithError}&pendente=${eventoHistoricoState.searchPendente}`,
      { replace: true }
    );
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pageSize = +event.target.value;
    dispatch(eventoHistoricoActions.setPageSize({ pageSize: pageSize }));
    navigate(
      `/policial/evento-historico?term=${eventoHistoricoState.term}&page=${eventoHistoricoState.page}&pageSize=${pageSize}&withError=${eventoHistoricoState.searchWithError}&pendente=${eventoHistoricoState.searchPendente}`,
      { replace: true }
    );
  };

  function searchProcedimento(numero: number) {
    return () => {
      // dispatch(eventoHistoricoActions.setSearchTerm({ term: numero }));
      navigate(`/policial/procedimento?term=${numero}`);
    };
  }

  return (
    <>
      <Suspense fallback={<LinearProgress />}>
        <Await resolve={eventos}>
          {(result) => (
            <>
              <Typography variant="body2" sx={{ pt: 2 }}>
                Apresentando o(s) primeiro(s) <b>{result.data.length}</b> de <b>{result.total}</b>
              </Typography>
              <Fade in={true}>
                <Grid container spacing={{ xs: 1, md: 1.5 }} sx={{ pt: 2, pl: 3 }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Número do Procedimento</TableCell>
                          <TableCell>Evento</TableCell>
                          <TableCell>Número MGP</TableCell>
                          <TableCell>Data Evento</TableCell>
                          <TableCell>data Integração</TableCell>
                          <TableCell>Erro</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {navigation.state === "loading" && (
                          <TableRow hover role="checkbox">
                            <TableCell colSpan={7}>
                              <LinearProgress />
                            </TableCell>
                          </TableRow>
                        )}
                        {navigation.state !== "loading" &&
                          result &&
                          result.data.map((procedimento: any) => {
                            return (
                              // <Grid item key={procedimento._id} xs={12} sm={6} md={4} lg={3} xl={12 / 5}>
                              //   <EventoHistoricoCard procedimento={procedimento} />
                              // </Grid>
                              <TableRow hover role="checkbox" tabIndex={-1} key={procedimento._id}>
                                <TableCell>{procedimento.numero}</TableCell>
                                <TableCell>{procedimento.tipoEvento}</TableCell>
                                <TableCell>{procedimento.numeroMGP}</TableCell>
                                <TableCell>{prettyDateTime(procedimento.dataEvento)}</TableCell>
                                <TableCell>{prettyDateTime(procedimento.dataIntegracao)}</TableCell>
                                <TableCell>{procedimento.erroAoIntegraMGP}</TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="text"
                                    color="primary"
                                    onClick={searchProcedimento(procedimento.numero)}
                                  >
                                    <SearchIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 50]}
                            colSpan={4}
                            count={result.total}
                            rowsPerPage={eventoHistoricoState.pageSize}
                            page={eventoHistoricoState.page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": "rows per page",
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            // ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Fade>
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export default EventoHistoricoGrid;

export async function eventoHistoricoLoader({ request, params }: any) {
  const searchParams = new URL(request.url).searchParams;
  const term = searchParams.get("term") || store.getState().eventoHistorico.term || "";
  const categoria = searchParams.get("categoria") || store.getState().eventoHistorico.categoria || "";
  const page = searchParams.get("page") || store.getState().eventoHistorico.page || "0";
  const pageSize = searchParams.get("pageSize") || store.getState().eventoHistorico.pageSize || "10";
  const pendente = searchParams.get("pendente") || store.getState().eventoHistorico.searchPendente.toString();
  const withError = searchParams.get("withError") || store.getState().eventoHistorico.searchWithError.toString();
  console.log(term, pendente, page, pageSize);
  return defer({ eventos: eventoHistoricoService.findByFilter(term, pendente, withError, +categoria, +page, +pageSize) });
}

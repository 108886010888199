import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";

const Qualificados = ({ qualificados, onSelect }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  function selectQualificado(qualificado, index) {
    setSelectedIndex(index);
    return onSelect(qualificado);
  }

  return (
    <List component="nav" aria-label="Lista de Qualificados no Inquérito" sx={{ p: 0, m: 0 }}>
      {qualificados &&
        qualificados.map((qualificado, index) => {
          return (
            <ListItemButton
              key={index}
              selected={selectedIndex === index}
              onClick={(event) => selectQualificado(qualificado, index)}
              sx={{ p: 0, m: 0 }}
            >
              <ListItemText>
                <Typography variant="body2" color="initial">
                  {qualificado.nome}
                </Typography>
              </ListItemText>
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default Qualificados;

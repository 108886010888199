import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, IconButton } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import loginService from "src/Service/login-service";

type Props = { menuId: string };

function ProfileMenu({ menuId }: Props) {
  const [avatarPhoto, setAvatarPhoto] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<any>(true);

  const { accounts } = useMsal();
  const name = (accounts[0] && accounts[0].name) || "Admin";

  React.useEffect(() => {
    const getAvatar = async () => {
      const photo = await loginService.getPhoto();
      setAvatarPhoto(photo);
      setIsLoading(false);
    };
    getAvatar();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="end"
        aria-label="Account of current user"
        aria-controls="header-profile-menu"
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        {/* <AccountCircle /> */}
        {isLoading && <AccountCircle />}
        {!isLoading && <Avatar src={avatarPhoto} alt={name} aria-label={name} sx={{ width: 24, height: 24 }} />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <NavLink to="/profile">Profile</NavLink>
        </MenuItem>
        {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      </Menu>
    </>
  );
}

export default ProfileMenu;

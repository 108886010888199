import { Box, Typography } from "@mui/material";
import React from "react";

const Ocorrencias = ({ ocorrencias }) => {
  return (
    <Box sx={{ ml: 1.5 }}>
      {ocorrencias &&
        ocorrencias.length > 0 &&
        ocorrencias.map((ocorrencia) => {
          return (
            <Typography variant="h6" color="primary.dark" key={ocorrencia.idTipoDelito}>
              {ocorrencia.descricaoTipoDelito}
            </Typography>
          );
        })}
    </Box>
  );
};

export default Ocorrencias;

import {
  Button,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import { Suspense, ChangeEvent } from "react";
import {
  Await,
  defer,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { prettyDateTime } from "src/common/util";
import store from "src/store";
import remessaPcerjService from "./service/remessa-pcerj-service";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { remessaPcerjActions } from "src/store/remessa-pcerj-slice";

const RemessaPcerjGrid = () => {
  const navigation = useNavigation();
  const { remessas }: any = useLoaderData();
  const remessaPcerjState = store.getState().remessaPcerj;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(remessaPcerjActions.setPage({ page: newPage }));
    navigate(
      `/policial/remessa-pcerj?term=${remessaPcerjState.term}&page=${newPage}&pageSize=${remessaPcerjState.pageSize}`,
      { replace: true }
    );
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pageSize = +event.target.value;
    dispatch(remessaPcerjActions.setPageSize({ pageSize: pageSize }));
    navigate(
      `/policial/remessa-pcerj?term=${remessaPcerjState.term}&page=${remessaPcerjState.page}&pageSize=${pageSize}}`,
      { replace: true }
    );
  };

  function searchProcedimento(numero: number) {
    return () => {
      // dispatch(remessaPcerjActions.setSearchTerm({ term: numero }));
      navigate(`/policial/procedimento?term=${numero}`);
    };
  }

  return (
    <>
      <Suspense fallback={<>...</>}>
        <Await resolve={remessas}>
          {(result) => (
            <>
              <Typography variant="body2" sx={{ pt: 2 }}>
                Apresentando o(s) primeiro(s) <b>{result.data.length} </b> de{" "}
                <b>{result.total}</b>
              </Typography>

              <Fade in={true}>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1.5 }}
                  sx={{ pt: 2, pl: 3 }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Número do remessa</TableCell>
                          <TableCell>Data Recebimento</TableCell>
                          <TableCell>Data Integração</TableCell>
                          <TableCell>Categoria</TableCell>
                          <TableCell>CNJ</TableCell>
                          <TableCell>Comarca</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {navigation.state === "loading" && (
                          <TableRow hover role="checkbox">
                            <TableCell colSpan={7}>
                              <LinearProgress />
                            </TableCell>
                          </TableRow>
                        )}
                        {navigation.state !== "loading" &&
                          result &&
                          result.data.map((remessa: any) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={remessa._id}
                              >
                                <TableCell>
                                  {remessa.procedimento.numero}
                                </TableCell>
                                <TableCell>
                                  {prettyDateTime(remessa.dataRecebimento)}
                                </TableCell>
                                <TableCell>
                                  {prettyDateTime(remessa.dataIntegracao)}
                                </TableCell>
                                <TableCell>
                                  {remessa.procedimento.categoria.descricao}
                                </TableCell>
                                <TableCell>
                                  {remessa.procedimento.numeroCnj}
                                </TableCell>
                                <TableCell>
                                  {remessa.procedimento.nomeComarca}
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="text"
                                    color="primary"
                                    onClick={searchProcedimento(
                                      remessa.procedimento.numero
                                    )}
                                  >
                                    <SearchIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 50]}
                            colSpan={4}
                            count={result.total}
                            rowsPerPage={remessaPcerjState.pageSize}
                            page={remessaPcerjState.page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": "rows per page",
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            // ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Fade>
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export default RemessaPcerjGrid;

export function remessaPcerjLoader({ request, params }: any) {
  const searchParams = new URL(request.url).searchParams;
  const term = searchParams.get("term") || store.getState().importa.term || "";
  const page = searchParams.get("page") || store.getState().importa.page || "0";
  const categoria =
    searchParams.get("categoria") || store.getState().importa.categoria || "0";
  const pageSize =
    searchParams.get("pageSize") || store.getState().importa.pageSize || "50";
  return defer({
    remessas: remessaPcerjService.findByFilter(
      term,
      +categoria,
      +page,
      +pageSize
    ),
  });
}

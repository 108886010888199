import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Outlet, useNavigate, useNavigation } from "react-router-dom";
import SearchBox from "../components/Filter/SearchBox";
import { eventoHistoricoActions as actions } from "src/store/evento-historico-slice";
import { Grid, MenuItem, Select, Switch } from "@mui/material";

const EventoHistoricoRootLayout = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const state = useSelector((state) => state.eventoHistorico);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function changeHandler(event) {
    dispatch(actions.setSearchTerm({ term: event.target.value }));
  }

  useEffect(() => {
    if (navigation.state !== "loading") {
      setIsLoading(false);
    }
  }, [isLoading, navigation]);

  function submitHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    dispatch(
      actions.addSearchHistory({
        term: state.term,
      })
    );
    navigate(
      `/policial/evento-historico?term=${state.term}&pendente=${state.searchPendente}&withError=${state.searchWithError}&page=0&pageSize=${state.pageSize}`
    );
  }

  function toggleSearchPendente() {
    setIsLoading(true);
    const searchPendente = !state.searchPendente;
    dispatch(actions.toggleSearchPendente());
    navigate(
      `/policial/evento-historico?term=${state.term}&pendente=${searchPendente}&withError=${state.searchWithError}&page=${state.page}&pageSize=${state.pageSize}`
    );
  }

  function toggleSearchWithError() {
    setIsLoading(true);
    const searchWithError = !state.searchWithError;
    // do not toggle before to use it, becouse dispatch is async
    dispatch(actions.toggleSearchWithError());
    navigate(
      `/policial/evento-historico?term=${state.term}&pendente=${state.searchPendente}&withError=${searchWithError}&page=${state.page}&pageSize=${state.pageSize}`
    );
  }
  function categoriaChangeHandler(event) {
    setIsLoading(true);
    const categoria = +event.target.value;
    // do not toggle before to use it, becouse dispatch is async
    dispatch(actions.setCategoria(categoria));
    navigate(
      `/policial/evento-historico?term=${state.term}&pendente=${state.searchPendente}&withError=${state.searchWithError}&categoria=${categoria}&page=${state.page}&pageSize=${state.pageSize}`
    );
  }

  return (
    <Form method="get" onSubmit={submitHandler}>
      <Grid container spacing={2}>
        <Grid item>
          <SearchBox
            defaultValue={state.term}
            changeHandler={changeHandler}
            history={state.searchHistory}
          ></SearchBox>
        </Grid>
        {/* <Grid item>
          <Select
            sx={{ width: 200, height: 45, backgroundColor: "#fff", mt: 2 }}
            labelId="tipo"
            id="tipo"
            defaultValue={eventoHistoricoState.categoria}
            label="Tipo Procedimento"
            onChange={categoriaChangeHandler}
          >
            <MenuItem value={0}>Todos</MenuItem>
            <MenuItem value={10}>AIAI</MenuItem>
            <MenuItem value={11}>AAPAI</MenuItem>
            <MenuItem value={13}>FLAGRANTE/AAPAI</MenuItem>
          </Select>
        </Grid> */}
        <Grid item>
          Com Erro
          <Switch
            checked={state.searchPendente}
            onChange={toggleSearchPendente}
          />
        </Grid>
        <Grid item>
          Pendente
          <Switch
            checked={state.searchWithError}
            onChange={toggleSearchWithError}
          />
        </Grid>
      </Grid>

      <Outlet />
    </Form>
  );
};

export default EventoHistoricoRootLayout;

import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import store from "./store";
import loginService from "./Service/login-service";
import queryClientInstance from "./Service/common/query-client";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <MsalProvider instance={loginService.getMsalInstance()}>
      <QueryClientProvider client={queryClientInstance}>
        <Provider store={store}>
          <App />
          <ReactQueryDevtools position="bottom-right" />
        </Provider>
      </QueryClientProvider>
    </MsalProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

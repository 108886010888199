export function matchSearchTerm(term, value) {
  try {
    if (!term || term === "") return true;
    return value.toString().toLowerCase().includes(term.toLowerCase());
  } catch (error) {
    return false;
  }
}
export function matchNgramTerm(term, value) {
  const terms = term.toString().toLowerCase().split(" ");
  if (terms.length === 1) return matchSearchTerm(term, value);
  const eachMatch = [];
  for (let i = 0; i < terms.length; i++) {
    eachMatch.push(matchSearchTerm(terms[i], value));
  }
  return eachMatch.every((v) => v === true);
}
const commons = {
  matchSearchTerm,
  matchNgramTerm,
};

export default commons;

import { Fade, Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import importaService from "src/Service/importa-service";
import IpImportaCard from "./IpImportaCard";
import store from "src/store";

export default function IpImportaGrid() {
  const { procedimentos } = useLoaderData() as any;
  return (
    <>
      <Suspense
        fallback={
          <>
            <br />
            <LinearProgress />
          </>
        }
      >
        <Await resolve={procedimentos}>
          {(result) => (
            <>
              <Typography variant="body2" sx={{ pt: 2 }}>
                {result.length} Resultados de&nbsp;
                {result.length > 50 ? "mais de 50..." : result.length} Total
              </Typography>
              <Fade in={true}>
                <Grid container spacing={{ xs: 1, md: 1.5 }} sx={{ pt: 2 }}>
                  {result &&
                    result.map((procedimento: any) => {
                      return (
                        <Grid
                          item
                          key={procedimento._id}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={12 / 5}
                        >
                          <IpImportaCard procedimento={procedimento} />
                        </Grid>
                      );
                    })}
                </Grid>
              </Fade>
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
}

export function ipImportaLoader({ request, params }: any) {
  const searchParams = new URL(request.url).searchParams;
  const term =
    searchParams.get("term") || store.getState().procedimento.term || "";
  const categoria =
    searchParams.get("categoria") || store.getState().importa.categoria || "0";
  const situacao =
    searchParams.get("situacao") || store.getState().importa.situacao || "0";
  return defer({
    procedimentos: importaService.findByFilter(
      term,
      categoria as string,
      situacao as string
    ),
  });
  // return defer({ procedimentos: importaService.findByFilter() });
}

import { Divider } from "@mui/material";
import React from "react";
import PdfFrame from "../../components/PdfFrame";
import TreeView from "./TreeView";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

const PdfTreeViewer = ({ procedimento }) => {
  const [selectPeca, setSelectedPeca] = React.useState({ peca: null, origem: null });

  function selectFileHandler(peca, origem) {
    setSelectedPeca({ peca, origem });
  }

  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={25} minSize={25}>
        <TreeView procedimento={procedimento} onSelect={selectFileHandler} />
      </Panel>
      <PanelResizeHandle>
        <Divider orientation="vertical" sx={{ m: 0.5 }} />
      </PanelResizeHandle>
      <Panel minSize={50}>{selectPeca.peca && <PdfFrame params={selectPeca} />}</Panel>
    </PanelGroup>
  );
};

export default PdfTreeViewer;

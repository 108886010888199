import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

type CategoriaSelectProps = {
  value?: number;
  changeHandler?: (event: SelectChangeEvent<number>) => void;
};

function CategoriaSelect(props: CategoriaSelectProps) {
  const handleChange = (event: SelectChangeEvent<number>) => {
    if (props.changeHandler) {
      props.changeHandler(event);
    } else {
      console.log("CategoriaSelect changeHandler not implemented");
    }
  };

  return (
    <Select
      sx={{ width: 200, height: 45, backgroundColor: "#fff", mt: 2 }}
      labelId="categoria-select-label"
      id="categoria"
      value={props.value || 0}
      label="Categoria"
      onChange={handleChange}
    >
      <MenuItem value={0}>Todos</MenuItem>
      <MenuItem value={2}>RO</MenuItem>
      <MenuItem value={3}>VPI</MenuItem>
      <MenuItem value={4}>Inquérito</MenuItem>
      <MenuItem value={5}>Sindicancia Sumária</MenuItem>
      <MenuItem value={7}>Flagrante</MenuItem>
      <MenuItem value={8}>Carta Precatória</MenuItem>
      <MenuItem value={9}>AIAI</MenuItem>
      <MenuItem value={10}>AAPAI</MenuItem>
      <MenuItem value={11}>FLAGRANTE/AAPAI</MenuItem>
      <MenuItem value={13}>Termo Circunstanciado</MenuItem>
      <MenuItem value={14}>BOC</MenuItem>
    </Select>
  );
}

export default CategoriaSelect;

import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import SideMenu from "./Header/SideMenu";
import ErrorBoundry from "src/Components/ErrorBoundry";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";

let denied = false;

function RootLayout() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  React.useEffect(() => {
    if (denied) {
      return;
    }
    if (!isAuthenticated && inProgress === "none") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
        denied = true;
        // redirect("/auth");
      });
    }
    console.log("isAuthenticated", isAuthenticated);
  }, [isAuthenticated, inProgress, instance]);

  return (
    <React.Fragment>
      <ErrorBoundry>
        <CssBaseline />
        <Header />
        <SideMenu />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme: any) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            p: 2,
          }}
        >
          <Box sx={{ mt: 7 }}></Box>
          <AuthenticatedTemplate>
            <Outlet />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div>Esta aplicação é para uso interno.</div>
          </UnauthenticatedTemplate>
        </Box>
      </ErrorBoundry>
    </React.Fragment>
  );
}

export default RootLayout;

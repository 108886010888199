import React from "react";

function KibanaDashboard() {
  const getKibanaUrlWithAuth = () => {
    const username = "monitoracao";
    const password = "monitora2023";
    const kibanaUrl =
      "https://kibana-externo.mprj.mp.br/app/dashboards#/view/341abd40-f3f8-11ed-90c4-a349f3be76d4?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-2w,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Monitoracao%20-%20JUD%20-%20Audit',viewMode:view)&hide-filter-bar=true";

    const encodedCredentials = btoa(`${username}:${password}`);
    return `${kibanaUrl}&auth=${encodedCredentials}`;
  };

  return (
    <div>
      <iframe title="kibana_board" src={getKibanaUrlWithAuth()} height="800" width="100%"></iframe>
    </div>
  );
}

export default KibanaDashboard;

import HistoricoCard from "./HistoricoCard";
// import { ExpandMore } from "@mui/icons-material";

const Historico = ({ historico }) => {
  return (
    <>
      {historico.map((item, index) => {
        return <HistoricoCard key={index} item={item} />;
      })}
    </>
  );
};

export default Historico;

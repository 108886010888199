import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import LabelValue from "../components/LabelValue";
import Assuntos from "./Assunto/Assuntos";
import Ocorrencias from "./Ocorrencia/Ocorrencias";

interface Props {
  procedimento: any;
}

const ProcedimentoView = ({ procedimento }: Props) => {
  if (!procedimento) {
    return <>Procedimento não encontrado ou acesso não autorizado</>;
  }
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h4" color="secondary">
          {procedimento.numero}
        </Typography>
        <Divider />
        <Grid container spacing={1} sx={{ m: 1 }}>
          <LabelValue label="Numero" value={procedimento.id}></LabelValue>
          <LabelValue label="Numero CNJ" value={procedimento.numeroCnj}></LabelValue>
          <LabelValue label="Data" value={procedimento.dataProcedimento} format="date"></LabelValue>
          <LabelValue label="Categoria" value={procedimento.categoria?.descricao}></LabelValue>
          <LabelValue label="Situacao" value={procedimento.situacao?.descricao}></LabelValue>
          <LabelValue label="Sigilo" value={procedimento.sigilo}></LabelValue>
          <LabelValue label="Id Rito" value={procedimento.idRito}></LabelValue>
          <LabelValue label="Descricao Rito" value={procedimento.descricaoRito}></LabelValue>
          <LabelValue label="nomeVara" value={procedimento.nomeVara}></LabelValue>
          <LabelValue label="nomeComarca" value={procedimento.nomeComarca}></LabelValue>
          <Divider />
          <LabelValue label="CircunscricaoFato" value={procedimento.descricaoDelegaciaCircunscricaoFato}></LabelValue>
          <LabelValue label="Registro" value={procedimento.descricaoDelegaciaRegistro}></LabelValue>
          <LabelValue label="Responsavel" value={procedimento.descricaoDelegaciaResponsavel}></LabelValue>
        </Grid>
      </Box>

      <Box sx={{ pb: 2 }}>
        <Typography variant="h5" color="secondary">
          Ocorrências
        </Typography>
        <Divider />
        <Ocorrencias ocorrencias={procedimento.ocorrencias} />
      </Box>
      <Assuntos assuntos={procedimento.controleMP.assuntos} />

      <Typography variant="h5" color="secondary">
        Alertas MP
      </Typography>
      <Divider />
      <Grid container spacing={1} sx={{ m: 1 }}>
        <LabelValue label="Fluxo Trabalho MP" value={procedimento.controleMP?.statusFluxoTrabalhoMP}></LabelValue>
        <LabelValue label="alertaRelatado" value={procedimento.controleMP?.alertaRelatado}></LabelValue>
        <LabelValue label="alertaMedidaProtetiva" value={procedimento.controleMP?.alertaMedidaProtetiva}></LabelValue>
        <LabelValue label="alertaMedidaCautelar" value={procedimento.controleMP?.alertaMedidaCautelar}></LabelValue>
        <LabelValue
          label="alertaPossuiPrisaoFlagrante"
          value={procedimento.controleMP?.alertaPossuiPrisaoFlagrante}
        ></LabelValue>
        <LabelValue label="alertaMinuta" value={procedimento.controleMP?.alertaMinuta}></LabelValue>
        <LabelValue label="foraDoMPRJ" value={procedimento.controleMP?.foraDoMPRJ}></LabelValue>
        <LabelValue label="finalizado" value={procedimento.controleMP?.finalizado}></LabelValue>
      </Grid>
    </>
  );
};

export default ProcedimentoView;

import * as React from "react";
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { headerActions } from "src/store/header-slice";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import BalanceIcon from "@mui/icons-material/Balance";
import AvatarMenuItem from "./AvatarMenuItem";
import { NavLink } from "react-router-dom";
import classes from "./SideMenu.module.css";

function SideMenu() {
  const headerState = useSelector((state: any) => state.header);
  const dispatch = useDispatch();

  function toggleSideBar() {
    dispatch(headerActions.toggleSideBar());
  }

  return (
    <React.Fragment>
      <Drawer open={headerState.sideBarOpen} onClose={toggleSideBar}>
        <Box
          sx={{ width: "100%", minWidth: 240, maxWidth: 360, bgcolor: "background.paper" }}
          onClick={toggleSideBar}
          onKeyDown={toggleSideBar}
        >
          <AvatarMenuItem />
          <Divider />
          <List>
            {/* <ListItem disablePadding button component={NavLink} to="/policial/dashboard"> */}
            <NavLink to="/policial/dashboard" className={({ isActive }) => (isActive ? classes.inactive : undefined)}>
              <ListItem disablePadding button>
                <ListItemButton>
                  <ListItemIcon>
                    <LocalPoliceIcon />
                  </ListItemIcon>
                  <ListItemText>Policial</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to="/judicial" className={({ isActive }) => (isActive ? classes.inactive : undefined)}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <BalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Judicial" />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default SideMenu;

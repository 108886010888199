import React from "react";
import LabelValue from "../../components/LabelValue";

const Endereco = (props) => {
  const { endereco } = props;

  if (!endereco) {
    return;
  }

  return (
    <>
      <LabelValue label="tipo" value={endereco.tipo} />
      <LabelValue label="descricao" value={endereco.descricao} />
      <LabelValue label="logradouro" value={endereco.logradouro} />
      <LabelValue label="numero" value={endereco.numero} />
      <LabelValue label="bairro" value={endereco.bairro} />
      <LabelValue label="municipio" value={endereco.municipio} />
      <LabelValue label="observacao" value={endereco.observacao} />
      <LabelValue label="UF" value={endereco.uf} />
    </>
  );
};

export default Endereco;

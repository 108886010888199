import React from "react";
import LabelValue from "../../components/LabelValue";

const Documento = (props) => {
  const { documento } = props;

  if (!documento) {
    return;
  }

  return (
    <>
      <LabelValue label="nome" value={documento.nome} />
      <LabelValue label="numero" value={documento.numero} />
      <LabelValue label="descricao" value={documento.descricao} />
      <LabelValue label="orgao" value={documento.orgao} />
    </>
  );
};

export default Documento;

import { createSlice } from "@reduxjs/toolkit";

const eventoHistoricoSlice = createSlice({
  name: "eventoHistorico",
  initialState: { term: "", page: 0, pageSize: 10, searchHistory: [], searchPendente: true, searchWithError: false, categoria: 0 },
  reducers: {
    setSearchTerm(state, action) {
      state.term = action.payload.term;
    },
    toggleSearchPendente(state) {
      state.searchPendente = !state.searchPendente;
    },
    toggleSearchWithError(state) {
      state.searchWithError = !state.searchWithError;
    },
    addSearchHistory(state, action) {
      if (state.term && state.term !== "") {
        console.log("state.term", state.term);
        state.searchHistory = state.searchHistory.filter((item) => item !== state.term);
        state.searchHistory.unshift(action.payload.term);
      }
    },
    setCategoria(state, action) {
      state.categoria = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload.pageSize;
    },
    setPage(state, action) {
      state.page = action.payload.page;
    },
  },
});

export const eventoHistoricoActions = eventoHistoricoSlice.actions;

export default eventoHistoricoSlice;

import React from "react";
import Documento from "./Documento";
import Endereco from "./Endereco";
import { v4 as uuid } from "uuid";
import { Box, Divider, Grid, Typography } from "@mui/material";
import LabelValue from "../../components/LabelValue";

const Qualificado = ({ qualificado }) => {
  return (
    <>
      {qualificado.envolvimentos &&
        qualificado.envolvimentos.length > 0 &&
        qualificado.envolvimentos.map((envolvimento) => {
          return (
            <Box key={uuid()}>
              <Typography variant="h5" color="primary">
                {envolvimento.descricao}:
                <small>
                  &nbsp;{envolvimento.descricaoDelito}&nbsp;&nbsp;- {envolvimento.ocorrencia_oid}
                </small>
              </Typography>
            </Box>
          );
        })}
      <Divider />
      <Grid container spacing={1} sx={{ pb: 1 }}>
        <LabelValue label="nome" value={qualificado.nome} />
        <LabelValue label="" value={qualificado.ddescricao} />
        <LabelValue label="profissao" value={qualificado.profissao} />
        <LabelValue label="escolaridade" value={qualificado.escolaridade} />
        <LabelValue label="estadoCivil" value={qualificado.estadoCivil} />
        <LabelValue label="nascimento" value={qualificado.nascimento} format="date" />
        <LabelValue label="nacionalidade" value={qualificado.nacionalidade} />
        <LabelValue label="sexo" value={qualificado.sexo} />
        <LabelValue label="pai" value={qualificado.pai} />
        <LabelValue label="mae" value={qualificado.mae} />
      </Grid>

      <Typography variant="h6" color="secondary">
        Endereço(s)
      </Typography>
      <Grid container spacing={1} sx={{ pb: 1 }}>
        {qualificado.endereco &&
          qualificado.endereco.length > 0 &&
          qualificado.endereco.map((endereco) => {
            return (
              <React.Fragment key={uuid()}>
                <Divider />
                <Endereco endereco={endereco} />
              </React.Fragment>
            );
          })}
      </Grid>
      <Typography variant="h6" color="secondary">
        Documentos(s)
      </Typography>
      <Grid container spacing={1} sx={{ pb: 1 }}>
        {qualificado.documentos &&
          qualificado.documentos.length > 0 &&
          qualificado.documentos.map((documento) => {
            return (
              <React.Fragment key={uuid()}>
                <Divider />
                <Documento documento={documento} />
              </React.Fragment>
            );
          })}
      </Grid>
    </>
  );
};

export default Qualificado;

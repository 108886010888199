import React from "react";
import SearchInput from "./SearchInput";

interface Props {
  changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
  history?: string[];
  name?: string;
}

const SearchBox = ({ changeHandler = () => {}, defaultValue = "", name = "term", history = [] }: Props) => {
  return <SearchInput name={name} changeHandler={changeHandler} defaultValue={defaultValue} history={history} />;
};

export default SearchBox;

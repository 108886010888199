import { Card, CardActionArea, CardContent, Grid, Slide, Typography, LinearProgress } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import delegaciaService from "src/Service/delegacia-service";
import eventoHistoricoService from "src/Service/evento-historico-service";
import importaService from "src/Service/importa-service";
import orgaoService from "src/Service/orgao-service";
import procedimentosService from "./Procedimentos/service/procedimentos-service";
import remessaPcerjService from "./RemessaPcerj/service/remessa-pcerj-service";

function PolicialNavigation() {
  const [procedimentoCount, setProcedimentoCount] = React.useState<number | undefined>(0);
  const [delegaciaCount, setDelegaciaCount] = React.useState<number | undefined>(0);
  const [orgaoCount, setOrgaoCount] = React.useState<number | undefined>(0);
  const [importaCount, setImportaCount] = React.useState<number | undefined>(0);
  const [eventoHistoricoCount, setEventoHistoricoCount] = React.useState<number | undefined>(0);
  const [remessaPcerjCount, setRemessaPcerjCount] = React.useState<number | undefined>(0);

  React.useEffect(() => {
    procedimentosService.count().then((iCounter) => setProcedimentoCount(iCounter));
  }, []);

  React.useEffect(() => {
    delegaciaService.count().then((iCounter) => setDelegaciaCount(iCounter));
  }, []);

  React.useEffect(() => {
    orgaoService.count().then((iCounter) => setOrgaoCount(iCounter));
  }, []);

  React.useEffect(() => {
    importaService.count().then((iCounter) => setImportaCount(iCounter));
  }, []);

  React.useEffect(() => {
    eventoHistoricoService.count().then((iCounter) => setEventoHistoricoCount(iCounter));
  }, []);

  React.useEffect(() => {
    remessaPcerjService.count().then((iCounter) => setRemessaPcerjCount(iCounter));
  }, []);

  return (
    <Slide direction="down" in={true}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Card sx={{ minWidth: 170 }}>
            {/* <NavLink to="/policial/dashboard" className={({ isActive }) => (isActive ? classes.inactive : undefined)}> */}
            <NavLink to="/policial/dashboard" className={undefined}>
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Dashboard
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    1 (relatório)
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 160 }}>
            <NavLink to="/policial/procedimento">
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Procedimentos
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {procedimentoCount && procedimentoCount > 0 ? (
                      procedimentoCount
                    ) : (
                      <LinearProgress sx={{ m: 1.5 }} />
                    )}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid>
{/* 
        <Grid item>
          <Card sx={{ minWidth: 160 }}>
            <NavLink to="/policial/delegacia">
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Delegacias
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {delegaciaCount && delegaciaCount > 0 ? delegaciaCount : <LinearProgress sx={{ m: 1.5 }} />}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 160 }}>
            <NavLink to="/policial/orgao">
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Orgãos
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {orgaoCount && orgaoCount > 0 ? orgaoCount : <LinearProgress sx={{ m: 1.5 }} />}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid> */}

        <Grid item>
          <Card sx={{ minWidth: 160 }}>
            <NavLink to="/policial/importa">
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Importa
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {importaCount && importaCount > 0 ? importaCount : <LinearProgress sx={{ m: 1.5 }} />}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ minWidth: 160 }}>
            <NavLink to="/policial/evento-historico">
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Evento Histórico
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {eventoHistoricoCount && eventoHistoricoCount > 0 ? (
                      eventoHistoricoCount
                    ) : (
                      <LinearProgress sx={{ m: 1.5 }} />
                    )}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ minWidth: 160 }}>
            <NavLink to="/policial/remessa-pcerj">
              <CardActionArea>
                <CardContent sx={{ pt: 1, pb: 1, margin: 0 }}>
                  <Typography variant="h6" color="secondary">
                    Remessa Pcerj
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    {remessaPcerjCount && remessaPcerjCount > 0 ? (
                      remessaPcerjCount
                    ) : (
                      <LinearProgress sx={{ m: 1.5 }} />
                    )}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </NavLink>
          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default PolicialNavigation;

import * as React from "react";
import { Avatar, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import loginService from "src/Service/login-service";

function AvatarMenuItem() {
  const [avatarPhoto, setAvatarPhoto] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<any>(true);

  const { accounts } = useMsal();
  const name = (accounts[0] && accounts[0].name) || "Admin";

  React.useEffect(() => {
    const getAvatar = async () => {
      const photo = await loginService.getPhoto();
      setAvatarPhoto(photo);
      setIsLoading(false);
    };
    getAvatar();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: 64,
        backgroundColor: "primary.dark",
        "&:hover": {
          backgroundColor: "primary.main",
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {isLoading && (
                <Avatar src="https://i.ibb.co/rx5DFbs/avatar.png" alt="admin" sx={{ width: 56, height: 56 }} />
              )}
              {!isLoading && <Avatar src={avatarPhoto} alt="admin" sx={{ width: 56, height: 56 }} />}
            </ListItemIcon>
            &nbsp;
            <ListItemText>
              <Typography color="white">{name} </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export default AvatarMenuItem;

import instance from "src/Service/axios-msal-service";
import BaseService from "src/Service/base-serivce";

export class RemessaPcerjService extends BaseService {
  constructor() {
    super("remessa-pcerj");
  }

  async findByFilter(
    term: string,
    categoria: number,
    page: number,
    pageSize: number
  ) {
    try {
      const response = await instance.get(
        `${this.api}?term=${term}&categoria=${categoria}&page=${page}&pageSize=${pageSize}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return [];
    }
  }
}

const remessaPcerjService = new RemessaPcerjService();

export default remessaPcerjService;

import { createSlice } from "@reduxjs/toolkit";

const procedimentoSlice = createSlice({
  name: "procedimento",
  initialState: {
    term: "",
    page: 0,
    pageSize: 10,
    orgao: "",
    searchHistory: [],
    categoria: 0,
    situacao: 0,
  },
  reducers: {
    setSearchTerm(state, action) {
      state.term = action.payload.term;
    },
    setOrgao(state, action) {
      state.orgao = action.payload.orgao;
    },
    addSearchHistory(state, action) {
      if (state.term && state.term !== "") {
        state.searchHistory = state.searchHistory.filter(
          (item) => item !== state.term
        );
        state.searchHistory.unshift(action.payload.term);
      }
    },
    setCategoria(state, action) {
      state.categoria = action.payload;
    },
    setSituacao(state, action) {
      state.situacao = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload.pageSize;
    },
    setPage(state, action) {
      state.page = action.payload.page;
    },
  },
});

export const procedimentoActions = procedimentoSlice.actions;

export default procedimentoSlice;

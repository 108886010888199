import { Grid, LinearProgress, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Outlet, useNavigate, useNavigation } from "react-router-dom";
import SearchBox from "../components/Filter/SearchBox";
import { remessaPcerjActions } from "src/store/remessa-pcerj-slice";
import CategoriaSelect from "../components/CategoriaSelect";

const RemessaPcerjRootLayout = () => {
  const state = useSelector((state) => state.remessaPcerj);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function changeHandler(event) {
    dispatch(remessaPcerjActions.setSearchTerm({ term: event.target.value }));
  }

  useEffect(() => {
    if (navigation.state !== "loading") {
      setIsLoading(false);
    }
  }, [isLoading, navigation]);

  function submitHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    dispatch(remessaPcerjActions.addSearchHistory({ term: state.term }));
    navigate(
      `/policial/remessa-pcerj?term=${state.term}&page=0&pageSize=${state.pageSize}`
    );
  }

  function categoriaChangeHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    const categoria = +event.target.value;
    // do not toggle before to use it, becouse dispatch is async
    dispatch(remessaPcerjActions.setCategoria(categoria));
    navigate(
      `/policial/remessa-pcerj?term=${state.term}&categoria=${categoria}&page=0&pageSize=${state.pageSize}`
    );
  }

  return (
    <Form method="get" onSubmit={submitHandler}>
      <Grid container justifyContent="left" spacing={2}>
        <Grid item>
          <SearchBox
            defaultValue={state.term}
            changeHandler={changeHandler}
            history={state.searchHistory}
          ></SearchBox>
        </Grid>
        <Grid item>
          <CategoriaSelect
            changeHandler={categoriaChangeHandler}
            value={state.categoria}
          />
        </Grid>
      </Grid>
      <Outlet />
    </Form>
  );
};

export default RemessaPcerjRootLayout;

import React from "react";
import MovimentoCard from "./MovimentoCard";

const Movimentos = ({ movimentos }) => {
  return (
    <>
      {movimentos.map((item, index) => {
        return <MovimentoCard movimento={item} key={index} />;
      })}
    </>
  );
};

export default Movimentos;

import { createSlice } from "@reduxjs/toolkit";

const headerSlice = createSlice({
  name: "header",
  initialState: {
    menuId: "header-profile-menu",
    sideBarOpen: false,
  },
  reducers: {
    setMenuId(state, action) {
      state.menuId = action.payload;
    },
    toggleSideBar(state) {
      state.sideBarOpen = !state.sideBarOpen;
    },
  },
});

export const headerActions = headerSlice.actions;

export default headerSlice;

import { createTheme, ThemeOptions } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

// declare module "@mui/material/styles" {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#81342f",
      light: "#dc988f",
    },
    secondary: {
      main: "#B79266",
    },
  },
  // props: {
  //   MuiList: {
  //     dense: true,
  //   },
  //   MuiMenuItem: {
  //     dense: true,
  //   },
  //   MuiTable: {
  //     size: "small",
  //   },
  // },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1000,
      lg: 1300,
      xl: 1600,
    },
  },
  spacing: 6,
};

export const theme = createTheme(themeOptions);

import { BASE_URL } from "src/config";
import queryClientInstance from "./common/query-client";
import instance from "./axios-msal-service";

export class OrgaoService {
  private api = BASE_URL + "/orgao-eletronico";
  async findAll() {
    const query = {
      queryKey: [this.api],
      queryFn: async () => await instance.get(this.api),
    };
    const result = await queryClientInstance.getQueryData<any>(query.queryKey);
    if (result) {
      return await result.data;
    }
    const response = await queryClientInstance.fetchQuery<any>(query);
    return await response.data;
  }
  async count(): Promise<number> {
    try {
      const response = await instance.get(`${this.api}/count`);
      return await response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return -1;
    }
  }
}

const orgaoService = new OrgaoService();

export default orgaoService;

import React from "react";
import { Box, IconButton, InputBase, Paper, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { v4 as uuid } from "uuid";

const SearchInput = (props: any) => {
  const inputEl = React.useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openSearchHistory = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeSearchHistory = () => {
    setAnchorEl(null);
  };
  const handleSelectHistory = (item: string) => {
    setAnchorEl(null);
    if (inputEl.current) {
      inputEl.current.value = item;
      changeHandler({ target: inputEl.current });
    }
  };

  const changeHandler =
    props.changeHandler ||
    ((e: any) => {
      e.prventDefault();
    });

  return (
    <Box sx={{ pt: 2 }}>
      <Paper sx={{ p: "px 4px", display: "flex", alignItems: "center", width: 400 }}>
        <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={openSearchHistory}>
          <MenuIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1, width: 500 }}
          id={props.name}
          name={props.name}
          onChange={changeHandler}
          defaultValue={props.defaultValue}
          inputRef={inputEl}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {props.history && props.history.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeSearchHistory}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {props.history.map((item: any) => {
            return (
              <MenuItem onClick={handleSelectHistory.bind(null, item)} key={uuid()}>
                {item}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Box>
  );
};

export default SearchInput;

import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

const LabelValue = ({ label, value, format }: any) => {
  // if (label === "alertaMedidaCautelar") {
  //   console.log(format);
  // }
  if (format !== "bool" && (!value || value === "0")) {
    return <></>;
  }
  let formattedValue = value;
  if (format === "date") {
    formattedValue = new Date(value).toLocaleDateString("pt-BR");
  }
  if (format === "datetime") {
    formattedValue = new Date(value).toLocaleDateString("pt-BR");
    formattedValue += " - " + new Date(value).toLocaleTimeString("pt-BR");
  }
  if (format === "number") {
    formattedValue = value.toLocaleString("pt-BR");
  }
  if (format === "bool" || typeof value === "boolean") {
    if (value === undefined || value === null || value === "") {
      return <></>;
    } else {
      formattedValue = value ? "Sim" : "Não";
    }
  }
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="body1" color="primary.dark">
          <b>{label}</b>
        </Typography>
        <Typography variant="body2" color="initial">
          {formattedValue}
        </Typography>
      </Grid>
    </>
  );
};

export default LabelValue;

import { createSlice } from "@reduxjs/toolkit";

const orgaoSlice = createSlice({
  name: "orgao",
  initialState: { term: "", searchHistory: [] },
  reducers: {
    setSearchTerm(state, action) {
      state.term = action.payload.term;
    },
    addSearchHistory(state, action) {
      if (state.term && state.term !== "") {
        state.searchHistory = state.searchHistory.filter((item) => item !== state.term);
        state.searchHistory.unshift(action.payload.term);
      }
    },
  },
});

export const orgaoActions = orgaoSlice.actions;

export default orgaoSlice;

import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

type SituacaoSelectProps = {
  value?: number;
  changeHandler?: (event: SelectChangeEvent<number>) => void;
};

function SituacaoSelect(props: SituacaoSelectProps) {
  const handleChange = (event: SelectChangeEvent<number>) => {
    if (props.changeHandler) {
      props.changeHandler(event);
    } else {
      console.log("Situacao Select changeHandler not implemented");
    }
  };

  return (
    <Select
      sx={{ width: 200, height: 45, backgroundColor: "#fff", mt: 2 }}
      labelId="situacao-select-label"
      id="situacao"
      value={props.value || 0}
      label="Situacao"
      onChange={handleChange}
    >
      <MenuItem value={0}>Todos</MenuItem>
      <MenuItem value={1}>Em Andamento</MenuItem>
      <MenuItem value={3}>Suspenso</MenuItem>
      <MenuItem value={4}>Transf.outra DP</MenuItem>
      <MenuItem value={6}>Justiça (RELATADO)</MenuItem>
      <MenuItem value={7}>Enviado à Justiça</MenuItem>
      <MenuItem value={8}>Enviado Corregedoria</MenuItem>
      <MenuItem value={9}>Enviado ao JECRIM</MenuItem>
      <MenuItem value={11}> Enc.Outros Órgãos</MenuItem>
      <MenuItem value={13}> Trans. DP não Inform</MenuItem>
      <MenuItem value={12}> Devolv. pela Justiça</MenuItem>
      <MenuItem value={14}> Cumprida</MenuItem>
      <MenuItem value={15}> Apensado</MenuItem>
      <MenuItem value={16}> Arquivada DP</MenuItem>
      <MenuItem value={17}> Devolv. por Outros Orgãos</MenuItem>
      <MenuItem value={21}> Enviado ao MP - Solicita Prazo</MenuItem>
      <MenuItem value={21}> 22 Enviado ao MP (Relatado)</MenuItem>
      <MenuItem value={26}> Oferecimento de Denúncia</MenuItem>
      <MenuItem value={32}> Recebido do MP</MenuItem>
      <MenuItem value={40}> Enviado ao MP Sol.( Medida Cautelar)</MenuItem>
      <MenuItem value={64}> Devolvido pela Corregedoria</MenuItem>
      <MenuItem value={62}> Recebido da Justiça</MenuItem>
      <MenuItem value={70}>
        Devolvido do MP (fim de convênio) sem físico
      </MenuItem>
      <MenuItem value={71}>
        Devolvido do MP (fim de convênio) com físico
      </MenuItem>
    </Select>
  );
}

export default SituacaoSelect;

import { LinearProgress } from "@mui/material";
import React from "react";
import procedimentosService from "../Procedimentos/service/procedimentos-service";

const PdfFrame = ({ params }) => {
  const { peca, origem } = params;
  const [file, setFile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    setErrorMessage(null);
    async function fetchPdf() {
      try {
        const response = await procedimentosService.getPdf(peca);
        setFile(response.data);
      } catch (error) {
        if (error.response.status === 404) {
          console.log(error.response.status);
          setErrorMessage("Arquivo não encontrado");
        } else {
          console.error(error);
          setErrorMessage("Erro ao carregar arquivo");
        }
        // return error;
      } finally {
        setIsLoading(false);
      }
    }
    if (peca && origem) {
      fetchPdf();
    }
  }, [peca, origem]);

  if (isLoading) return <LinearProgress />;
  let pdfUrl = file ? URL.createObjectURL(file) : null;
  if (errorMessage) return <div>{errorMessage}</div>;
  return <iframe src={pdfUrl} width="100%" height="900" title="pdf-file" style={{ border: "none" }}></iframe>;
};

export default PdfFrame;

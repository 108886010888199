import React from "react";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const TreeView = ({ procedimento, onSelect }) => {
  const [value, setValue] = React.useState("1");
  const [selectedIndex, setSelectedIndex] = React.useState();
  const documentos = procedimento.pecasPcerj.sort((a, b) => a.ordem - b.ordem);
  const promocoes = procedimento.controleMP.promocoes;

  function getIdArquivoFisico(id) {
    const result = procedimento.controleMP.anexosProcedimento.find((anexo) => anexo.id.toString() === id.toString());
    if (result) return result.idArquivoFisico;
    return null;
  }

  const pecas = documentos.map((documento) => {
    const idArquivoFisico = getIdArquivoFisico(documento._id);
    const isPromocao =
      promocoes &&
      promocoes.length > 0 &&
      promocoes.find((promocao) => promocao.idArquivo.toString() === idArquivoFisico.toString());
    return { ...documento, idArquivoFisico: idArquivoFisico, isPromocao: isPromocao };
  });

  function selectHandler(peca, origem, index) {
    setSelectedIndex(index);
    if (origem === "peca") {
      onSelect(peca.idArquivoFisico, origem);
    } else {
      onSelect(peca.idArquivo, origem);
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <TabList onChange={handleChange}>
        <Tab label="PCERJ" value="1" />
        <Tab label="MPRJ" value="2" />
      </TabList>
      <TabPanel value="1" sx={{ m: 0, p: 0 }}>
        <List component="nav" aria-label="main mailbox folders">
          {pecas &&
            pecas.length > 0 &&
            pecas.map((peca, index) => {
              return (
                <ListItemButton
                  key={index}
                  selected={selectedIndex === index}
                  onClick={(event) => selectHandler(peca, "peca", index)}
                  sx={{ p: 0, m: 0 }}
                >
                  <ListItemText>
                    <Typography variant="body2" color="initial">
                      {peca.isPromocao && (
                        <span style={{ color: "red" }}>
                          {peca.ordem} - {peca.descricaoTipo}
                        </span>
                      )}
                      {!peca.isPromocao && (
                        <span style={{ color: "green" }}>
                          {peca.ordem} - {peca.descricaoTipo}
                        </span>
                      )}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              );
            })}
        </List>
      </TabPanel>
      <TabPanel value="2" sx={{ m: 0, p: 0 }}>
        {promocoes &&
          promocoes.length > 0 &&
          promocoes.map((peca, index) => {
            return (
              <ListItemButton
                key={index}
                selected={selectedIndex === index}
                onClick={(event) => selectHandler(peca, "promocao", index)}
                sx={{ p: 0, m: 0 }}
              >
                <ListItemText>
                  <Typography variant="body2" color="initial">
                    {new Date(peca.dataEnvioPromocao).toLocaleDateString("pt-BR")} - {peca.codigoMovimento}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            );
          })}
      </TabPanel>
    </TabContext>
  );
};

export default TreeView;

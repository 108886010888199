import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import loginService from "src/Service/login-service";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Card, CardContent, Typography } from "@mui/material";
import EmptyProfileCardHeader from "./Policial/components/EmptyProfileCardHeader";

function ProfilePage() {
  const { profile, photo } = useLoaderData();

  return (
    <Suspense fallback={<EmptyProfileCardHeader />}>
      <Await resolve={profile}>
        {(p) => (
          <Card>
            <CardHeader
              avatar={<Avatar aria-label="" src={photo} sx={{ width: 56, height: 56 }}></Avatar>}
              title={p.displayName}
              subheader={p.userPrincipalName}
            ></CardHeader>
            <CardContent>
              <Typography variant="body1" color="text.secondary">
                {p.jobTitle}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {p.officeLocation}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Telefone: {p.mobilePhone}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Await>
    </Suspense>
  );
}

export default ProfilePage;

export async function profileLoader() {
  try {
    const profile = loginService.getProfile();
    const photo = loginService.getPhoto();
    return defer({
      photo: await photo,
      profile: profile,
    });
  } catch (error) {
    console.log(error);
  }
}

import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Zoom from "@mui/material/Zoom";
import React from "react";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { useSelector } from "react-redux";
import { defer, useLoaderData, useNavigate } from "react-router-dom";
import LabelValue from "../components/LabelValue";
import remessaPcerjService from "./service/remessa-pcerj-service";

const RemessaPcerjPage = () => {
  const [value, setValue] = React.useState("1");

  const { remessa } = useLoaderData();
  const remessaPCerjState = useSelector((state) => state.remessaPcerj);
  const navigate = useNavigate();
  function cancelHandler() {
    navigate(`../?term=${remessaPCerjState.term}&page=${remessaPCerjState.page}`, { replace: true });
  }

  if (!remessa) {
    return (
      <Modal open={true} onClose={cancelHandler}>
        <Zoom in={true}>
          <Card sx={{ width: "94%", height: "94%", top: "3%", left: "3%", position: "absolute", overflow: "auto" }}>
            <Button onClick={cancelHandler} sx={{ float: "right", pr: 0, fontSize: 18 }}>
              x
            </Button>
            <Typography variant="body1" color="initial">
              Procedimento não encontrado ou acesso não autorizado
            </Typography>
          </Card>
        </Zoom>
      </Modal>
    );
  }
  const procedimento = remessa.procedimento;
  const procedimentoKeys = Object.keys(procedimento);

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleConsutlarStage() {
    navigate(`/policial/procedimento/${procedimento._id}`);
  }

  return (
    <Modal open={true} onClose={cancelHandler}>
      <Zoom in={true}>
        <Card sx={{ width: "94%", height: "94%", top: "3%", left: "3%", position: "absolute", overflow: "auto" }}>
          <Button onClick={cancelHandler} sx={{ float: "right", pr: 0, fontSize: 18 }}>
            x
          </Button>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lasb API tabs example">
              <Tab label="Procedimento" value="1" />
              <Tab label="Json" value="2" />
            </TabList>
            <TabPanel value="1">
              <Box sx={{ pb: 2 }}>
                <LabelValue label="dataRecebimento" value={remessa.dataRecebimento} format="datetime" />
                <LabelValue label="dataIntegracao" value={remessa.dataIntegracao} format="datetime" />
                <Typography variant="h6" color="initial">
                  Dados Procedimento
                </Typography>
                <Grid container spacing={1} sx={{ m: 1 }}>
                  <LabelValue label="categoria" value={procedimento.categoria.descricao} format="string" />
                  <LabelValue label="situacao" value={procedimento.situacao.descricao} format="string" />
                  {procedimentoKeys.map((key, index) => (
                    <>
                      {}
                      {typeof procedimento[key] !== "object" && (
                        <LabelValue key={key} label={key} value={procedimento[key]} format="string" />
                      )}
                    </>
                  ))}
                </Grid>
                <Button variant="text" color="primary" onClick={handleConsutlarStage}>
                  Consultar Stage <SearchIcon />
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <div style={{ fontSize: "0.75em" }}>
                <JSONPretty id="json-pretty" data={procedimento} space="6"></JSONPretty>
              </div>
            </TabPanel>
          </TabContext>
        </Card>
      </Zoom>
    </Modal>
  );
};

export default RemessaPcerjPage;

export async function remessaLoader({ params }) {
  const { id } = params;
  return defer({ remessa: await remessaPcerjService.findById(id) });
}

import { Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import LabelValue from "../../components/LabelValue";

const Assuntos = ({ assuntos }) => {
  return (
    <Box sx={{ pb: 2 }}>
      <Typography variant="h5" color="secondary">
        Assuntos
      </Typography>
      <Divider />
      {assuntos.map((item, index) => {
        return (
          <Card
            key={item.idAssunto}
            sx={{
              margin: 1,
              ":hover": {
                boxShadow: 8,
              },
              boxShadow: 2,
            }}
          >
            <CardContent>
              <Grid container spacing={1}>
                <LabelValue label="DELITO" value={item.delitoPcerj} />
                <LabelValue label="Id Assunto" value={item.idAssunto} />
                <LabelValue label="Codigo Assunto" value={item.codigoAssunto} />
                <LabelValue label="folha" value={item.folha} format="bool" />
                <LabelValue label="Nome Assunto" value={item.nomeAssunto} />
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default Assuntos;

import { BASE_URL } from "src/config";
import instance from "./axios-msal-service";

export class OpenService {
  private api = BASE_URL + "/openai";
  async test(id: any) {
    const result = await instance.get(`${this.api}/${id}`);
    return await result.data;
  }
}

const openaiService = new OpenService();

export default openaiService;

import { Grid } from "@mui/material";
import LabelValue from "../../components/LabelValue";

const EnderecoImporta = ({ endereco }) => {
  return (
    <Grid container spacing={1} sx={{ pb: 1 }}>
      <LabelValue label="bairro" value={endereco.bairro} />
      <LabelValue label="cep" value={endereco.cep} />
      <LabelValue label="logradouro" value={endereco.logradouro} />
      <LabelValue label="municipio" value={endereco.municipio} />
      <LabelValue label="numero" value={endereco.numero} />
      <LabelValue label="tipo" value={endereco.tipo} />
      <LabelValue label="descricao" value={endereco.descricao} />
      <LabelValue label="uf" value={endereco.uf} />
    </Grid>
  );
};

export default EnderecoImporta;

import axios from "axios";
import { Form, Outlet } from "react-router-dom";

import DelegaciaSearchBox from "./DelegaciaSearchBox";
import { useDispatch, useSelector } from "react-redux";
import { delegaciaActions } from "src/store/delegacia-slice";

const DelegaciaRootLayout = () => {
  const delegaciaState = useSelector((state) => state.delegacia);
  const dispatch = useDispatch();

  function submitHandler(event) {
    event.preventDefault();
    dispatch(delegaciaActions.addSearchHistory({ term: delegaciaState.term }));
  }

  return (
    <>
      <Form method="post" onSubmit={submitHandler}>
        <DelegaciaSearchBox history={delegaciaState.searchHistory}></DelegaciaSearchBox>
      </Form>
      <Outlet />
    </>
  );
};

export default DelegaciaRootLayout;

export async function findAll() {
  try {
    const response = await axios.get(`/api/delegacia`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return [];
}

export async function loadDelegacias() {
  return await findAll();
}

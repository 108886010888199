import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, graphConfig, loginRequest } from "../authConfig";

export class LoginService {
  /**
   * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
   */
  private msalInstance = new PublicClientApplication(msalConfig);
  private profile: any = null;
  private photo: any = null;
  private accessToken: any = null;
  public scopes = loginRequest.scopes;

  getMsalInstance() {
    return this.msalInstance;
  }

  async getProfile() {
    //  is Profile Cached
    if (!this.profile) {
      const token = await this.getAccessToken();
      const options = this.getOptions(token);
      const response = await fetch(graphConfig.graphMeEndpoint, options);
      this.profile = await response.json();
    }
    return this.profile;
  }

  async getPhoto() {
    if (!this.photo) {
      const token = await this.getAccessToken();
      const options = this.getOptions(token);
      const response: any = await fetch(graphConfig.graphMePhotoEndpoint, options).catch((error) => console.log(error));
      const url = window.webkitURL;
      const blob = await response.blob();
      this.photo = url.createObjectURL(blob);
    }
    return this.photo;
  }

  private getOptions(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    return options;
  }

  private async getAccessToken() {
    if (!this.accessToken) {
      const request: any = {
        account: this.msalInstance.getAllAccounts()[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await this.msalInstance.acquireTokenSilent(request);
      this.accessToken = response.accessToken;
    }
    return this.accessToken;
  }
}

const loginService = new LoginService();

export default loginService;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, InputBase, Menu, MenuItem, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { orgaoActions } from "src/store/orgao-slice";
import { v4 as uuid } from "uuid";

const OrgaoSearchBox = (props: any) => {
  const inputEl = React.useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const orgaoState = useSelector((state: any) => state.orgao);
  const dispatch = useDispatch();

  function changeHandler(event: any) {
    setTimeout(() => dispatch(orgaoActions.setSearchTerm({ term: event.target.value })), 500);
  }

  const openSearchHistory = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeSearchHistory = () => {
    setAnchorEl(null);
  };
  const handleSelectHistory = (item: string) => {
    setAnchorEl(null);
    if (inputEl.current) {
      inputEl.current.value = item;
      changeHandler({ target: inputEl.current });
    }
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Paper sx={{ p: "px 4px", display: "flex", alignItems: "center", width: 400 }}>
        <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={openSearchHistory}>
          <MenuIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1, width: 500 }}
          id="term"
          name="term"
          onChange={changeHandler}
          defaultValue={orgaoState.term}
          inputRef={inputEl}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {props.history && props.history.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeSearchHistory}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {props.history.map((item: any) => {
            return (
              <MenuItem onClick={handleSelectHistory.bind(null, item)} key={uuid()}>
                {item}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Box>
  );
};

export default OrgaoSearchBox;

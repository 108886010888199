import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, LinearProgress, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import React from "react";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
// import procedimentosService from "src/Service/procedimentos-service";
import procedimentosService from "../service/procedimentos-service";
import LabelValue from "../../components/LabelValue";
import OcorrenciasImporta from "../Ocorrencia/OcorrenciasImporta";
import Enquadramento from "./Enquadramento";

const ImportaView = ({ pid }) => {
  const [value, setValue] = React.useState("1");
  const [procedimento, setProcedimento] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchImporta(pid) {
      setIsLoading(true);
      try {
        const ip_importa = await procedimentosService.getImporta(pid);
        setProcedimento(ip_importa);
        setIsLoading(false);
      } catch (error) {
        // console.log(error);
        setProcedimento();
        setIsLoading(false);
      }
    }
    fetchImporta(pid);
  }, [pid]);

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) return <LinearProgress />;

  if (!procedimento) return <Typography>Procedimento não encontrado</Typography>;

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label="lab API tabs example">
        <Tab label="Ocorrências" value="1" />
        <Tab label="Enquadramento" value="2" />
        <Tab label="JSON" value="3" />
      </TabList>
      <TabPanel value="1">
        <Typography variant="h5" color="secondary">
          Dinâmica
        </Typography>
        <Typography variant="body2" color="initial">
          {procedimento.dinamica}
        </Typography>
        <Grid container spacing={1}>
          <LabelValue label="Guia Remessa" value={procedimento.guiaRemessa} />
          <LabelValue label="Origem" value={procedimento.origem} />
          <LabelValue label="Origem Doc" value={procedimento.origemDoc} />
        </Grid>
        {procedimento.ocorrencias && procedimento.ocorrencias.length > 0 && (
          <>
            <Typography variant="h6" color="secondary">
              Ocorrências: {procedimento.ocorrencias.length}
            </Typography>
            <OcorrenciasImporta ocorrencias={procedimento.ocorrencias} />
          </>
        )}
        {procedimento.prisaoCautelar && procedimento.prisaoCautelar.length > 0 && (
          <div className="mp-tabs-content">
            <h4>
              <small>Cautelares: {procedimento.prisaoCautelar.length}</small>
            </h4>
            {procedimento.prisaoCautelar &&
              procedimento.prisaoCautelar.length > 0 &&
              procedimento.prisaoCautelar.map((cautelar) => (
                <p>
                  - {cautelar.protocolo} {cautelar.representante}
                </p>
              ))}
          </div>
        )}
      </TabPanel>
      <TabPanel value="2">
        <Enquadramento procedimento={procedimento} />
      </TabPanel>
      <TabPanel value="3">
        <div style={{ fontSize: "0.7em" }}>
          <JSONPretty id="json-pretty" data={procedimento} space="6"></JSONPretty>
        </div>
      </TabPanel>
    </TabContext>
  );
};

export default ImportaView;

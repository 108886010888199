import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardActions, CardContent, Collapse, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React from "react";
import JSONPretty from "react-json-pretty";
import LabelValue from "../../components/LabelValue";
// import { ExpandMore } from "@mui/icons-material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function HistoricoCard({ item }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      sx={{
        m: 1,
        ":hover": {
          boxShadow: 8,
        },
        boxShadow: 2,
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <LabelValue label="EVENTO" value={item.eventoHistorico} />
          <LabelValue label="SERVIDOR" value={item.nomeServidorAcao} />
          <LabelValue label="PROMOTOR" value={item.nomePromotorResponsavel} />
          <LabelValue label="DATA" value={item.data} />
          <LabelValue label="MOVIMENTO" value={item.descricaoMovimento} />
          <LabelValue label="Orgao Distribuido" value={item.nomeOrgaoDistribuido} />
          <LabelValue label="ID PROCEDIMENTO MGP" value={item.idNumeroProcedimentoMGP} />
          <LabelValue label="INTEGRADO MGP" value={item.integradoAoMGP} />
          <LabelValue label="DATA INTEGRAÇÃO MGP" value={item.dataIntegracaoMGP} />
          <LabelValue label="DEVE SER INTEGRADO" value={item.deveSerIntegradoMGP} />
          <LabelValue label="UsuarioResponsavelPromocao" value={item.nomeUsuarioResponsavelPromocao} />
          <LabelValue label="matricula" value={item.matriculaUsuarioResponsavelPromocao} />
          <LabelValue label="prazo" value={item.prazo} />
          <LabelValue label="numeroMP" value={item.numeroMP} />
          <LabelValue label="md5PecaPromocao" value={item.md5PecaPromocao} />
          <LabelValue label="inLocalDestino" value={item.inLocalDestino} />
          <LabelValue label="movimentoFinalizador" value={item.movimentoFinalizador} />
          <LabelValue label="codigoMovimento" value={item.codigoMovimento} />
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          alignSelf: "stretch",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          pr: 3,
        }}
      >
        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div style={{ fontSize: "0.75em" }}>
            <JSONPretty data={item} />
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default HistoricoCard;

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

type Props = { procedimento: any };

function ProcedimentoCard({ procedimento }: Props) {
  const navigate = useNavigate();
  const prettyDate = (value: any) => {
    const aDate = parseISO(value);
    try {
      return format(aDate, "dd/MM/yyyy");
    } catch (err) {
      return "Pendente";
    }
  };
  function getEntrada(item: any) {
    if (item.caixaIP && item.caixaIP.dataUltimaEntrada) {
      return prettyDate(item.caixaIP.dataUltimaEntrada);
    } else {
      return prettyDate(item.dataRecebimento);
    }
  }
  const getOcorrencias = (item: any, pos: any) => {
    if (item.ocorrencias && item.ocorrencias[pos]) {
      return item.ocorrencias[pos].descricaoTipoDelito;
    }
  };
  const getMunicipio = (item: any) => {
    if (item.ocorrencias && item.ocorrencias[0]) {
      return item.ocorrencias[0].descricaoMunicipio;
    }
  };
  const clickHandler = (id: number) => {
    navigate(`/policial/procedimento/${id}`);
  };
  return (
    <>
      <Card
        sx={{
          minHeight: 250,
          maxHeight: 250,
          display: "flex",
          flexDirection: "column",
          overflow: "no",
          ":hover": {
            boxShadow: 10,
          },
          boxShadow: 2,
          cursor: "pointer",
        }}
        onClick={clickHandler.bind(null, procedimento.id)}
      >
        <CardHeader
          sx={{ pt: 1.6, pb: 0.6 }}
          title={
            <Typography variant="body2" sx={{ pb: 0, fontSize: 16 }}>
              <b>{procedimento.numero}</b>
            </Typography>
          }
          action={
            <Typography variant="body2" sx={{ pb: 0, fontSize: 12 }}>
              <b>{procedimento.categoria?.descricao}</b>
            </Typography>
          }
          subheader={
            <Typography variant="body2" sx={{ color: "rgb(136, 14, 79)" }}>
              <b>{procedimento.controleMP.numeroCnj}&nbsp;</b>
            </Typography>
          }
        />
        <Box sx={{ display: "flex", alignItems: "center", pb: 0 }}>
          <Stack direction="row" spacing={1} sx={{ pl: 2.2, flexGrow: 1 }}>
            {procedimento.controleMP?.alertaRelatado && (
              <Tooltip
                title={`${procedimento.controleMP?.alertaRelatado} Central de Inquérito`}
              >
                <Avatar
                  aria-label="recipe"
                  alt="asfasgs"
                  sx={{ width: 28, height: 28, bgcolor: "primary.dark" }}
                >
                  <small>R</small>
                </Avatar>
              </Tooltip>
            )}
            {procedimento.controleMP?.alertaMedidaProtetiva && (
              <Tooltip
                title={`${procedimento.controleMP?.alertaMedidaProtetiva} Central de Inquérito`}
              >
                <Avatar
                  aria-label="recipe"
                  alt="asfasgs"
                  sx={{ width: 28, height: 28, bgcolor: "secondary.dark" }}
                >
                  <small>MP</small>
                </Avatar>
              </Tooltip>
            )}
            {procedimento.controleMP?.alertaMedidaCautelar && (
              <Tooltip
                title={`${procedimento.controleMP?.alertaMedidaCautelar} Central de Inquérito`}
              >
                <Avatar
                  aria-label="recipe"
                  alt="asfasgs"
                  sx={{ width: 28, height: 28, bgcolor: "secondary.dark" }}
                >
                  <small>MC</small>
                </Avatar>
              </Tooltip>
            )}
            {procedimento.controleMP?.alertaPossuiPrisaoFlagrante && (
              <Tooltip
                title={`${procedimento.controleMP?.alertaPossuiPrisaoFlagrante} Central de Inquérito`}
              >
                <Avatar
                  aria-label="recipe"
                  alt="asfasgs"
                  sx={{ width: 28, height: 28, bgcolor: "secondary.dark" }}
                >
                  <small>R</small>
                </Avatar>
              </Tooltip>
            )}
          </Stack>
          <Typography
            variant="body2"
            sx={{ float: "right", pr: 2, fontSize: 12 }}
          >
            {procedimento?.situacao?.descricao}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 1,
            pt: 0,
            pb: 0,
            m: 0,
          }}
        >
          <IconButton aria-label="previous">
            <ArrowDownwardIcon />
          </IconButton>
          <Typography variant="body2">
            <b>{getEntrada(procedimento.controleMP)}</b>
          </Typography>
          <IconButton aria-label="next">
            <ArrowOutwardIcon />
          </IconButton>
          <Typography variant="body2">
            <b>{prettyDate(procedimento.controleMP.dataEnvioPromocao)}</b>
          </Typography>
        </Box>

        <CardContent sx={{ pt: 0, pb: 0 }}>
          <Typography variant="body2">
            <>
              <b>{procedimento.descricaoRito}</b>
              {getMunicipio(procedimento) && (
                <small>
                  <br />
                  <b>{getMunicipio(procedimento)}</b>
                </small>
              )}
              {getOcorrencias(procedimento, 0) && (
                <>
                  <br />
                  <small>{getOcorrencias(procedimento, 0)}</small>
                </>
              )}
              {getOcorrencias(procedimento, 1) && (
                <>
                  <br />
                  <small>{getOcorrencias(procedimento, 1)}</small>
                </>
              )}
              {procedimento.nomeComarca && (
                <>
                  <br />
                  <small>{procedimento.nomeComarca}</small>
                </>
              )}
              {procedimento.nomeVara && (
                <>
                  <small>
                    <br />
                    <small>{procedimento.nomeVara}</small>
                  </small>
                </>
              )}
              {procedimento.statusFluxoTrabalhoMP && (
                <>
                  <small>
                    <br />
                    <small>{procedimento.statusFluxoTrabalhoMP}</small>
                  </small>
                </>
              )}
            </>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default ProcedimentoCard;

import { BASE_URL } from "src/config";
import queryClientInstance from "src/Service/common/query-client";
import instance from "src/Service/axios-msal-service";

export class ProcedimentoService {
  private api = BASE_URL + "/procedimento";

  async findById(id: string) {
    try {
      const response = await instance.get(`${this.api}/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return {};
    }
  }
  async checkOne(id: string) {
    try {
      const response = await instance.get(`${this.api}/check/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return {};
    }
  }

  async findByFilter(
    term: string,
    categoria: string,
    situacao: string,
    page: string
  ) {
    try {
      const response = await instance.get(
        `${this.api}?term=${term}&categoria=${categoria}&situacao=${situacao}&page=${page}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return [];
    }
  }

  async inOutHistory() {
    const query = this.inOutQuery();
    const result = await queryClientInstance.getQueryData<any>(query.queryKey);
    if (result) {
      return result.data;
    }
    const response = await queryClientInstance.fetchQuery<any>(query);
    return await response.data;
  }

  async update(procedimento: any) {
    //...
    queryClientInstance.invalidateQueries({ queryKey: ["statistics"] });
    queryClientInstance.invalidateQueries({ queryKey: [this.api] });
  }

  inOutQuery() {
    return {
      queryKey: ["statistics"],
      queryFn: async () => await instance.get(this.api + "/statistics"),
    };
  }

  async getPdf(id: string) {
    const url = `${BASE_URL}/procedimento/peca/${id}`;
    const response = await instance.get(url, {
      responseType: "blob",
      timeout: 30000,
    });
    return response;
  }
  async getImporta(pid: string) {
    const ip_importa = await instance.get(`${this.api}/importa/${pid}`);
    return await ip_importa.data;
  }

  async count(): Promise<number> {
    try {
      const response = await instance.get(`${this.api}/count`);
      return await response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return -1;
    }
  }
}

const procedimentosService = new ProcedimentoService();

export default procedimentosService;

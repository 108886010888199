import React from "react";
import { Link } from "react-router-dom";

const IntegraLogo = () => {
  return (
    <div className="logo-sistema">
      <Link to="/">
        <div className="logo-mprj-integra">
          <svg width="231" height="32" viewBox="0 0 231 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <mask
                id="mask0_19579_579886"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="231"
                height="32"
              >
                <path d="M230.848 0H0V32H230.848V0Z" fill="white" />
              </mask>
              <g mask="url(#mask0_19579_579886)">
                <path
                  d="M20.6579 25.0764V13.1247L13.3169 24.7029C12.9636 25.2364 12.5025 25.4736 11.8379 25.4736C11.1732 25.4736 10.7122 25.2364 10.3589 24.7029L3.0418 13.1247V25.0764H0V8.17446C0 7.03028 0.772425 6.52637 1.80831 6.52637C2.60469 6.52637 3.01785 6.7635 3.39508 7.38006L11.8858 20.9798L20.4363 7.38006C20.8136 6.79315 21.1668 6.52637 21.9393 6.52637C22.9512 6.52637 23.7476 7.03028 23.7476 8.17446V25.0764H20.6579Z"
                  fill="white"
                />
                <path
                  d="M29.652 19.8893V25.0766H26.2988V6.92383H38.3343C42.6335 6.92383 43.9808 8.49486 43.9808 12.7811V14.3521C43.9808 18.6917 42.7233 19.8893 38.3343 19.8893H29.652ZM40.6276 12.9412C40.6276 10.9729 40.0528 10.4157 38.2445 10.4157H29.652V16.6168H38.2924C40.0588 16.6168 40.6276 16.1129 40.6276 14.198V12.9412Z"
                  fill="white"
                />
                <path
                  d="M58.178 25.0766L49.3819 18.4783V25.0766H46.0527V6.92383H57.8247C62.0342 6.92383 63.7526 8.01466 63.7526 12.378V13.6289C63.7526 17.8321 61.8785 18.9229 57.7768 18.9229H55.1302L63.9502 25.0707H58.178V25.0766ZM60.4055 12.5914C60.4055 10.6469 59.8127 10.3267 57.6032 10.3267H49.3819V15.8639H57.6032C59.7648 15.8639 60.4055 15.5437 60.4055 13.5755V12.5914Z"
                  fill="white"
                />
                <path
                  d="M65.166 25.0766V21.6144H68.8066C70.9682 21.6144 71.4951 20.7904 71.4951 18.6621V6.92383H74.8483V18.8459C74.8483 23.4226 73.5489 25.0707 69.0701 25.0707H65.166V25.0766Z"
                  fill="white"
                />
                <path d="M83.3477 1.5V30.5" stroke="white" />
                <mask
                  id="mask1_19579_579886"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x="90"
                  y="5"
                  width="141"
                  height="22"
                >
                  <path d="M230.848 5.5H90.8477V26.5H230.848V5.5Z" fill="white" />
                </mask>
                <g mask="url(#mask1_19579_579886)">
                  <mask
                    id="mask2_19579_579886"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="90"
                    y="5"
                    width="141"
                    height="22"
                  >
                    <path d="M230.848 5.5H90.8477V26.5H230.848V5.5Z" fill="white" />
                  </mask>
                  <g mask="url(#mask2_19579_579886)">
                    <path
                      d="M97.2553 5.5C96.5472 5.5 95.9736 6.06955 95.9736 6.77273V8.04545H93.4102V25.8636H98.5371V8.04545V6.77273C98.5371 6.06955 97.9635 5.5 97.2553 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M92.1294 5.5C91.4212 5.5 90.8477 6.06955 90.8477 6.77273C90.8477 7.47591 91.4212 8.04545 92.1294 8.04545H93.4111V6.77273C93.4111 6.06955 92.8375 5.5 92.1294 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M115.2 5.5V17.7309L107.51 5.52864V5.5H107.491H102.383V25.8636H107.51V13.6455L115.2 25.8636H120.327V5.5H115.2Z"
                      fill="white"
                    />
                    <path
                      d="M142.116 5.5H135.707H130.581H124.172V10.5909H130.581V25.8636H135.707V10.5909H142.116V5.5Z"
                      fill="white"
                    />
                    <path
                      d="M161.344 5.5H148.526C147.11 5.5 145.963 6.63909 145.963 8.04545C145.963 9.45182 147.11 10.5909 148.526 10.5909H161.344C162.76 10.5909 163.907 9.45182 163.907 8.04545C163.907 6.63909 162.76 5.5 161.344 5.5ZM149.808 9.31818C149.1 9.31818 148.526 8.74864 148.526 8.04545C148.526 7.34227 149.1 6.77273 149.808 6.77273C150.516 6.77273 151.09 7.34227 151.09 8.04545C151.09 8.74864 150.516 9.31818 149.808 9.31818Z"
                      fill="white"
                    />
                    <path
                      d="M161.342 13.1367H148.524C147.108 13.1367 145.961 14.2758 145.961 15.6822C145.961 17.0885 147.108 18.2276 148.524 18.2276H161.342C162.758 18.2276 163.905 17.0885 163.905 15.6822C163.905 14.2758 162.758 13.1367 161.342 13.1367ZM149.806 16.9549C149.098 16.9549 148.524 16.3854 148.524 15.6822C148.524 14.979 149.098 14.4094 149.806 14.4094C150.514 14.4094 151.088 14.979 151.088 15.6822C151.088 16.3854 150.514 16.9549 149.806 16.9549Z"
                      fill="white"
                    />
                    <path
                      d="M161.342 20.7725H148.524C147.108 20.7725 145.961 21.9116 145.961 23.3179C145.961 24.7243 147.108 25.8634 148.524 25.8634H161.342C162.758 25.8634 163.905 24.7243 163.905 23.3179C163.905 21.9116 162.758 20.7725 161.342 20.7725ZM149.806 24.5906C149.098 24.5906 148.524 24.0211 148.524 23.3179C148.524 22.6147 149.098 22.0452 149.806 22.0452C150.514 22.0452 151.088 22.6147 151.088 23.3179C151.088 24.0211 150.514 24.5906 149.806 24.5906Z"
                      fill="white"
                    />
                    <path
                      d="M187.742 16.0799H177.488V19.5004H182.003C181.064 20.6617 179.622 21.4094 178.004 21.4094C175.171 21.4094 172.877 19.1313 172.877 16.3185C172.877 13.5058 175.171 11.2276 178.004 11.2276C180.154 11.2276 181.993 12.5449 182.756 14.4094H188.075C187.175 9.70036 183.009 6.13672 178.004 6.13672C172.342 6.13672 167.75 10.6963 167.75 16.3185C167.75 21.9408 172.342 26.5004 178.004 26.5004C182.548 26.5004 186.399 23.5635 187.745 19.5004H187.742V16.0799Z"
                      fill="white"
                    />
                    <path
                      d="M230.841 13.8588C230.675 9.3343 226.919 5.70703 222.324 5.70703C217.729 5.70703 213.974 9.33749 213.807 13.8588H213.801V16.3025V21.1866V26.0707H218.719V21.1866H225.929V25.9688H230.848V21.1866V16.3025V13.8588H230.841ZM225.929 16.3025H218.719V14.1707C218.719 12.1979 220.338 10.5911 222.324 10.5911C224.311 10.5911 225.929 12.1979 225.929 14.1707V16.3025Z"
                      fill="white"
                    />
                    <path
                      d="M209.866 13.7731C209.866 9.56354 206.415 6.13672 202.176 6.13672H197.049H191.922V26.5004H197.049V21.4094H200.09L204.201 26.5004H209.866L205.483 20.6681C208.072 19.4367 209.866 16.8085 209.866 13.7731ZM197.049 11.2276H202.176C203.589 11.2276 204.739 12.3699 204.739 13.7731C204.739 15.1763 203.589 16.3185 202.176 16.3185H197.049V11.2276Z"
                      fill="white"
                    />
                  </g>
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_19579_579886">
                <rect width="231" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </Link>
    </div>
  );
};

export default IntegraLogo;

import { BASE_URL } from "src/config";
import queryClientInstance from "./common/query-client";
import instance from "./axios-msal-service";

export class BaseService {
  api: string;

  constructor(baseApi: string) {
    this.api = BASE_URL + (baseApi.startsWith("/") ? baseApi : "/" + baseApi);
  }

  getApiUrl(): string {
    return this.api;
  }

  public async findAll() {
    const query = {
      queryKey: [this.api],
      queryFn: async () => await instance.get(this.api),
    };
    const result = await queryClientInstance.getQueryData<any>(query.queryKey);
    if (result) {
      return await result.data;
    }
    const response = await queryClientInstance.fetchQuery<any>(query);
    return await response.data;
  }

  public async count(): Promise<number> {
    try {
      console.log(this.api);
      const response = await instance.get(`${this.api}/count`);
      return await response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return -1;
    }
  }

  public async findById(id: string) {
    try {
      const response = await instance.get(`${this.api}/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return {};
    }
  }
}

export default BaseService;

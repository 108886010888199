import instance from "./axios-msal-service";
import BaseService from "./base-serivce";

export class EventoHistoricoService extends BaseService {
  constructor() {
    super("evento-historico");
  }

  async findByFilter(
    term: string,
    pendente: string,
    withError: string,
    categoria = 0,
    page = 0,
    pageSize = 10
  ) {
    try {
      const uriSerch = `${this.api}?term=${term}&pendente=${pendente}&withError=${withError}&categoria=${categoria}&page=${page}&pageSize=${pageSize}`;
      console.log(uriSerch);
      const response = await instance.get(uriSerch);
      return response.data;
    } catch (error: any) {
      console.log(error.response.status);
      return [];
    }
  }
}

const eventoHistoricoService = new EventoHistoricoService();

export default eventoHistoricoService;

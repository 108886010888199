import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";

const DelegaciaCard = ({ delegacia }) => {
  const prettyDate = (value) => {
    const aDate = parseISO(value);
    try {
      return format(aDate, "dd/MM/yyyy");
    } catch (err) {
      return "Pendente";
    }
  };

  return (
    <>
      <Card
        variant="dense"
        sx={{
          minHeight: 200,
          maxHeight: 280,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          ":hover": {
            boxShadow: 15,
          },
          boxShadow: 2,
        }}
      >
        <CardHeader
          sx={{ pt: 1.6, pb: 1 }}
          title={
            <Typography variant="body2" sx={{ pb: 0 }}>
              <b>{delegacia.descricaoPcerj}</b>
            </Typography>
          }
          subheader={
            <Typography variant="body2" sx={{ color: "rgb(136, 14, 79)" }}>
              <b>{delegacia.descricaoCraai}&nbsp;</b>
            </Typography>
          }
        />
        <Divider />
        <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
          <IconButton aria-label="previous">
            <ArrowDownwardIcon />
          </IconButton>
          <Typography variant="body2">
            <b>{prettyDate(delegacia.dataCadastro)}</b>
          </Typography>
          <IconButton aria-label="next">
            <ArrowOutwardIcon />
          </IconButton>
          <Typography variant="body2">
            <b>{prettyDate(delegacia.dataDePara)}</b>
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Stack direction="row" spacing={1} sx={{ pl: 2.2, pb: 1, flexGrow: 1 }}>
            <Tooltip title={delegacia && delegacia.ativa ? "Ativo" : "Inativo"}>
              <Avatar
                aria-label="recipe"
                alt={delegacia && delegacia.ativa ? "Ativo" : "Inativo"}
                sx={{ width: 28, height: 28, bgcolor: "secondary.dark" }}
              >
                <small>{delegacia && delegacia.ativa ? "A" : "I"}</small>
              </Avatar>
            </Tooltip>
            {delegacia.numeroCentralInquerito && (
              <Tooltip title={`${delegacia.numeroCentralInquerito} Central de Inquérito`}>
                <Avatar
                  aria-label={`${delegacia.numeroCentralInquerito} Central de Inquérito`}
                  alt={`${delegacia.numeroCentralInquerito} Central de Inquérito`}
                  sx={{ width: 28, height: 28, bgcolor: "secondary.dark" }}
                >
                  <small>{delegacia.numeroCentralInquerito}ci</small>
                </Avatar>
              </Tooltip>
            )}
            {delegacia.especializada && (
              <Tooltip title="Especializada">
                <Avatar
                  aria-label="recipe"
                  alt="Especializada"
                  sx={{ width: 28, height: 28, bgcolor: "secondary.dark" }}
                >
                  <small>E</small>
                </Avatar>
              </Tooltip>
            )}
          </Stack>
          <Typography variant="body2" sx={{ float: "right", pr: 2 }}>
            <b>{delegacia.descricaoComarca}</b>
          </Typography>
        </Box>
        <CardContent sx={{ pt: 0 }}>
          <Typography variant="body2">
            <>
              <b>{delegacia.descricaoComarca}</b>
              <br />
              <small>{delegacia.descricaoMprj}</small>
              <br />
              <small>{delegacia.emailCentralInquerito}</small>
            </>
          </Typography>
          {/* <Typography variant="body2">
            {delegacia.dataDesativacao && <>x{delegacia.dataDesativacao}</>}
          </Typography> */}
        </CardContent>
      </Card>
    </>
  );
};

export default DelegaciaCard;

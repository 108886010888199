import { Card, CardContent, CardMedia, Slide, Typography } from "@mui/material";
import React from "react";
import { AreaChart, XAxis, Tooltip, CartesianGrid, YAxis, Area } from "recharts";

const EntradasSaidasChart = ({ history }: any) => {
  if (!history) return null;
  const ticks: string[] = [];
  let total = 0;

  history.forEach((item: any) => {
    ticks.push(item.name);
    total += item.entrada + item.saida;
  });
  return (
    <Slide direction="up" in={true}>
      <Card sx={{ maxWidth: 845 }}>
        <CardMedia title="Chart">
          <AreaChart width={800} height={200} data={history} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" angle={-25} ticks={ticks} />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="entrada" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="saida" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
          </AreaChart>
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Entradas vs Saidas
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Total de {total.toLocaleString("pt-BR")} saidas de documentos no período (12 meses)
          </Typography>
        </CardContent>
      </Card>
    </Slide>
  );
};

export default EntradasSaidasChart;

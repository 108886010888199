import axios from "axios";
import loginService from "./login-service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const msalInstance = loginService.getMsalInstance();
    if (config.headers) {
      config.headers["Content-Type"] = "application/json";
    }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginService.scopes,
        account: account,
      });

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;

        if (config.headers && accessToken) {
          config.headers["Authorization"] = "Bearer " + accessToken;
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;

import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import LabelValue from "../../components/LabelValue";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardActions, Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import JSONPretty from "react-json-pretty";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function MovimentoCard({ movimento }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      sx={{
        m: 1,
        ":hover": {
          boxShadow: 8,
        },
        boxShadow: 2,
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <LabelValue label="idOrgao" value={movimento.idOrgao} />
          <LabelValue label="orgao" value={movimento.orgao} />
          <LabelValue label="matricula" value={movimento.matricula} />
          <LabelValue label="responsavel" value={movimento.responsavel} />
          <LabelValue label="data" value={movimento.data} format="date" />
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          alignSelf: "stretch",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          pr: 3,
        }}
      >
        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div style={{ fontSize: "0.75em" }}>
            <JSONPretty data={movimento} />
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default MovimentoCard;

import React from "react";
import JSONPretty from "react-json-pretty";
import { defer, useLoaderData, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-json-pretty/themes/monikai.css";
import { Button, Card, Modal, Typography } from "@mui/material";
import procedimentosService from "./service/procedimentos-service";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ProcedimentoView from "./ProcedimentoView";
import PdfTreeViewer from "./Tree/PdfTreeViewer";
import Movimentos from "./Movimento/Movimentos";
import Historico from "./Historico/Historico";
import ImportaView from "./Importa/ImportaView";
import Zoom from "@mui/material/Zoom";
import OpenAi from "../OpenAi/OpenAi";

const ProcedimentoPage = () => {
  const [value, setValue] = React.useState("1");

  const { procedimento } = useLoaderData();
  const procedimentoState = useSelector((state) => state.procedimento);
  const navigate = useNavigate();

  function cancelHandler() {
    navigate(`../?term=${procedimentoState.term}&page=${procedimentoState.page}`, { replace: true });
  }

  if (!procedimento) {
    return (
      <Modal open={true} onClose={cancelHandler}>
        <Zoom in={true}>
          <Card sx={{ width: "94%", height: "94%", top: "3%", left: "3%", position: "absolute", overflow: "auto" }}>
            <Button onClick={cancelHandler} sx={{ float: "right", pr: 0, fontSize: 18 }}>
              x
            </Button>
            <Typography variant="body1" color="initial">
              Procedimento não encontrado ou acesso não autorizado
            </Typography>
          </Card>
        </Zoom>
      </Modal>
    );
  }
  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Modal open={true} onClose={cancelHandler}>
      <Zoom in={true}>
        <Card sx={{ width: "94%", height: "94%", top: "3%", left: "3%", position: "absolute", overflow: "auto" }}>
          <Button onClick={cancelHandler} sx={{ float: "right", pr: 0, fontSize: 18 }}>
            x
          </Button>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Procedimento" value="1" />
              <Tab label="Peças" value="2" />
              <Tab label="Movimentos" value="3" />
              <Tab label="Historico" value="4" />
              <Tab label="Importa" value="5" />
              <Tab label="Json" value="6" />
              <Tab label="Open AI" value="7" />
            </TabList>
            <TabPanel value="1">
              <ProcedimentoView procedimento={procedimento} />
            </TabPanel>
            <TabPanel value="2">
              <PdfTreeViewer procedimento={procedimento} />
            </TabPanel>
            <TabPanel value="3">
              <Movimentos movimentos={procedimento.controleMP.movimentos} />
            </TabPanel>
            <TabPanel value="4">
              <Historico historico={procedimento.controleMP.historico} />
            </TabPanel>
            <TabPanel value="5" sx={{ m: 0, p: 0 }}>
              <ImportaView pid={procedimento.id} />
            </TabPanel>
            <TabPanel value="6">
              <div style={{ fontSize: "0.75em" }}>
                <JSONPretty id="json-pretty" data={procedimento} space="6"></JSONPretty>
              </div>
            </TabPanel>
            <TabPanel value="7">
              <OpenAi procedimento={procedimento}></OpenAi>
            </TabPanel>
          </TabContext>
        </Card>
      </Zoom>
    </Modal>
  );
};

export default ProcedimentoPage;

export async function procedimentoLoader({ params }) {
  const { id } = params;
  return defer({ procedimento: await procedimentosService.findById(id) });
}

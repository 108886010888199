import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";
import IntegraLogo from "./IntegraLogo";
import { useDispatch } from "react-redux";
import { headerActions } from "src/store/header-slice";

type Props = {};

const Header = (props: Props) => {
  const dispatch = useDispatch();
  function toggleSideBar() {
    dispatch(headerActions.toggleSideBar());
  }

  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="Abre o menu lateral"
          sx={{ mr: 2 }}
          onClick={toggleSideBar}
        >
          <MenuIcon />
        </IconButton>
        <IntegraLogo />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" noWrap sx={{ display: { xs: "none", sm: "block" } }}>
            &nbsp;&nbsp;Labs
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <IconButton size="large" aria-label="Show 4 new mails" color="inherit">
            <Badge badgeContent={1} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={0.5} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <ProfileMenu menuId="header-profile-menu" />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { QueryClient } from "@tanstack/react-query";

const queryClientInstance = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, // 10 minutes
    },
  },
});

console.log("queryClient");

export default queryClientInstance;

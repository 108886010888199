import React from "react";
import Qualificado from "../Qualificado/Qualificado";
import Qualificados from "../Qualificado/Qualificados";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Divider } from "@mui/material";

const Enquadramento = ({ procedimento }) => {
  const [selectedUser, setSelectedUser] = React.useState(null);

  function selectQualificado(qualificado) {
    setSelectedUser(qualificado);
  }
  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={25} minSize={25}>
        <Qualificados qualificados={procedimento.qualificados} onSelect={selectQualificado} />
      </Panel>
      <PanelResizeHandle>
        <Divider orientation="vertical" sx={{ m: 0.5 }} />
      </PanelResizeHandle>
      <Panel minSize={50}>{selectedUser && <Qualificado qualificado={selectedUser} />}</Panel>
    </PanelGroup>
  );
};

export default Enquadramento;

import { Box, Grid, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import React from "react";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import orgaoService from "src/Service/orgao-service";
import commons from "../common/common";
import OrgaoCard from "./OrgaoCard";

const OrgaoGrid = () => {
  const orgaos = useLoaderData();
  const orgaoState = useSelector((state) => state.orgao);
  const term = orgaoState.term;
  // const [filtered, setFiltered] = React.useState(orgaos);

  const filtered = React.useMemo(() => {
    const result = orgaos.filter((orgao) => {
      for (const key in orgao) {
        const matchedAtLeastOneField = commons.matchNgramTerm(term, orgao[key]);
        if (matchedAtLeastOneField) {
          return true;
        }
      }
      return false;
    });
    return result;
  }, [term, orgaos]);

  return (
    <Box>
      <Typography variant="body2" sx={{ pt: 2 }}>
        {filtered.length} Resultados de {orgaos.length} Total
      </Typography>
      <Fade in={true}>
        <Grid container spacing={{ xs: 1, md: 1.5 }} sx={{ pt: 2 }}>
          {filtered &&
            filtered.map((orgao) => {
              return (
                <Grid item key={orgao._id} xs={12} sm={6} md={4} lg={3} xl={12 / 5}>
                  <OrgaoCard orgao={orgao} />
                </Grid>
              );
            })}
        </Grid>
      </Fade>
    </Box>
  );
};

export default OrgaoGrid;

export async function orgaoLoader() {
  try {
    return await orgaoService.findAll();
  } catch (error) {
    console.log(error);
  }
  return [];
}

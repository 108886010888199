import { Card, CardContent, Grid } from "@mui/material";
import LabelValue from "../../components/LabelValue";
import EnderecoImporta from "../Importa/EnderecoImporta";

const OcorrenciasImporta = ({ ocorrencias }) => {
  return (
    <>
      {ocorrencias &&
        ocorrencias.length > 0 &&
        ocorrencias.map((ocorrencia) => {
          return (
            <Card
              key={ocorrencia.oid}
              sx={{
                m: 1,
                ":hover": {
                  boxShadow: 8,
                },
                boxShadow: 2,
              }}
            >
              <CardContent>
                <Grid container spacing={1} sx={{ pb: 1 }}>
                  <LabelValue label="Descricao " value={ocorrencia.descricao} />
                  <LabelValue label="Data Inicial " value={ocorrencia.dataInicial} format="date" />
                  <LabelValue label="Data Final " value={ocorrencia.dataFinal} format="date" />
                </Grid>
                <EnderecoImporta endereco={ocorrencia.endereco} />
              </CardContent>
            </Card>
          );
        })}
    </>
  );
};

export default OcorrenciasImporta;

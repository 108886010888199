import React from "react";
import { Typography, Box, Grid, Fade } from "@mui/material";

function Home() {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "80vh" }}
      >
        <Grid item xs={3}>
          <Box sx={{ flexGrow: 1 }}>
            <Fade in={true} timeout={5000}>
              <Typography variant="h2" color="textSecondary">
                <b>INTEGRA</b> Labs
              </Typography>
            </Fade>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Home;

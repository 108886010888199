import React from "react";
import { Outlet } from "react-router-dom";
import PolicialNavigation from "./PolicialNavigation";

function PolicialRootLayout() {
  return (
    <>
      <PolicialNavigation />
      <Outlet />
    </>
  );
}

export default PolicialRootLayout;

import React, { Suspense } from "react";
import { Await, defer, useRouteLoaderData } from "react-router-dom";
import { Container, Grid, LinearProgress } from "@mui/material";
import EntradasSaidasChart from "./EntradasSaidasChart";
import procedimentosService from "../Procedimentos/service/procedimentos-service";

function PolicialDashboard() {
  const { history } = useRouteLoaderData("total-documents-loader");
  return (
    <Container>
      <br />
      <Suspense fallback={<LinearProgress />}>
        <Grid sx={{ flexGrow: 1 }} direction="column" alignItems="center" container>
          <Await resolve={history}>{(result) => <EntradasSaidasChart history={result} />}</Await>
        </Grid>
      </Suspense>
    </Container>
  );
}

export default PolicialDashboard;

async function loadHistory() {
  try {
    return await procedimentosService.inOutHistory();
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function dashboardLoader() {
  return defer({
    history: loadHistory(),
  });
}

import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import Header from "./Header/Header";
import SideMenu from "./Header/SideMenu";

function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  let title = "Servidores Indisponíveis";
  let message = "Se o erro persistir, por favor, abra um chamado.";

  if (error.status === 500) {
    console.log("Error Caputured");
    console.log(error);
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "404";
    message = "Ops! Página não encontrada";
  }

  function backHandler() {
    navigate(-1);
  }
  return (
    <>
      <Header />
      <SideMenu />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "30vh" }}
      >
        <Grid item xs={3}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" color="initial">
              {title}
            </Typography>
            {message}
            <Button variant="text" color="primary" onClick={backHandler}>
              Voltar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ErrorPage;

import React from "react";
import { Form } from "react-router-dom";
import openaiService from "src/Service/openai-service";
import { LinearProgress, TextareaAutosize } from "@mui/material";

const OpenAi = ({ procedimento }: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [text, setText] = React.useState<any>();
  const idProcedimento = procedimento.id;

  React.useEffect(() => {
    async function getTest() {
      try {
        setIsLoading(true);
        const result = await openaiService.test(idProcedimento);
        setText(result);
      } catch (error) {
        console.log(error);
        setText({ text: "Erro ao gerar texto, limite de tokens atingido" });
      } finally {
        setIsLoading(false);
      }
    }
    getTest();
  }, [idProcedimento]);

  console.log(text);
  return (
    <Form method="post">
      {isLoading && <LinearProgress />}
      <TextareaAutosize
        defaultValue={text?.text}
        aria-label="Gerando texto.. aguarde"
        minRows={13}
        placeholder="Gerando texto.. aguarde"
        style={{ width: 740 }}
      />
    </Form>
  );
};

export default OpenAi;

export async function aiAnalize() {}

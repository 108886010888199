import React from "react";
import { Avatar, Card, CardContent, CardHeader } from "@mui/material";

function EmptyProfileCardHeader() {
  return (
    <Card>
      <CardHeader
        avatar={<Avatar aria-label="" src="https://i.ibb.co/rx5DFbs/avatar.png"></Avatar>}
        title="..."
        subheader="..."
      />
      <CardContent>...</CardContent>
    </Card>
  );
}

export default EmptyProfileCardHeader;

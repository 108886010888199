import { format, parseISO } from "date-fns";

export const prettyDate = (value: any) => {
  const aDate = parseISO(value);
  try {
    return format(aDate, "dd/MM/yyyy");
  } catch (err) {
    return "Pendente";
  }
};

export const prettyDateTime = (value: any) => {
  const aDate = parseISO(value);
  try {
    return format(aDate, "dd/MM/yyyy HH:mm:ss");
  } catch (err) {
    return "Pendente";
  }
};

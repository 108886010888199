import { createHashRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./Layout/ErrorPage";
import Home from "./Layout/Home";
import PolicialDashboard, { dashboardLoader } from "./Layout/Policial/Dashboard/PolicialDashboard";
import PolicialRootLayout from "./Layout/Policial/PolicialRootLayout";
import ProfilePage, { profileLoader } from "./Layout/ProfilePage";
import RootLayout from "./Layout/RootLayout";
import OrgaoEletronicoRootLayout from "./Layout/Policial/OrgaoEletronico/OrgaoEletronicoRootLayout";
import OrgaoGrid, { orgaoLoader } from "./Layout/Policial/OrgaoEletronico/OrgaoGrid";
import DelegaciaRootLayout from "./Layout/Policial/Delegacia/DelegaciaRootLayout";
import DelegaciaGrid, { delegaciaLoader } from "./Layout/Policial/Delegacia/DelegaciaGrid";
import ProcedimentoRootLayout from "./Layout/Policial/Procedimentos/ProcedimentoRootLayout";
import ProcedimentoGrid, { procedimentosLoader } from "./Layout/Policial/Procedimentos/ProcedimentoGrid";
import ProcedimentoPage, { procedimentoLoader } from "./Layout/Policial/Procedimentos/ProcedimentoPage";
import IpImportaRootLayout from "./Layout/Policial/IpImporta/IpImportaRootLayout";
import IpImportaGrid, { ipImportaLoader } from "./Layout/Policial/IpImporta/IpImportaGrid";
import EventoHistoricoRootLayout from "./Layout/Policial/EventoHistorico/EventoHistoricoRootLayout";
import EventoHistoricoGrid, { eventoHistoricoLoader } from "./Layout/Policial/EventoHistorico/EventoHistoricoGrid";
import RemessaPcerjRootLayout from "./Layout/Policial/RemessaPcerj/RemessaPcerjRootLayout";
import RemessaPcerjGrid, { remessaPcerjLoader } from "./Layout/Policial/RemessaPcerj/RemessaPcerjGrid";
import RemessaPcerjPage, { remessaLoader } from "./Layout/Policial/RemessaPcerj/RemessaPcerjPage";
import KibanaDashboard from "./Layout/Judicial/KibanaDashboard";

const router = createHashRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "policial",
        element: <PolicialRootLayout />,
        children: [
          {
            path: "dashboard",
            element: <PolicialDashboard />,
            id: "total-documents-loader",
            loader: dashboardLoader,
          },
          {
            path: "orgao",
            element: <OrgaoEletronicoRootLayout />,
            children: [{ index: true, element: <OrgaoGrid />, loader: orgaoLoader }],
          },
          {
            path: "delegacia",
            element: <DelegaciaRootLayout />,
            children: [{ index: true, element: <DelegaciaGrid />, loader: delegaciaLoader }],
          },
          {
            path: "procedimento",
            element: <ProcedimentoRootLayout />,
            children: [
              {
                index: true,
                element: <ProcedimentoGrid />,
                loader: procedimentosLoader,
              },
              { path: ":id", element: <ProcedimentoPage />, loader: procedimentoLoader },
            ],
          },
          {
            path: "importa",
            element: <IpImportaRootLayout />,
            children: [
              {
                index: true,
                element: <IpImportaGrid />,
                loader: ipImportaLoader,
              },
              // { path: ":id", element: <IpImportaPage />, loader: procedimentoLoader },
            ],
          },
          {
            path: "evento-historico",
            element: <EventoHistoricoRootLayout />,
            children: [
              {
                index: true,
                element: <EventoHistoricoGrid />,
                loader: eventoHistoricoLoader,
              },
            ],
          },
          {
            path: "remessa-pcerj",
            element: <RemessaPcerjRootLayout />,
            children: [
              {
                index: true,
                element: <RemessaPcerjGrid />,
                loader: remessaPcerjLoader,
              },
              { path: ":id", element: <RemessaPcerjPage />, loader: remessaLoader },
            ],
          },
        ],
      },
      {
        path: "profile",
        element: <ProfilePage />,
        loader: profileLoader,
      },
      {
        path: "judicial",
        element: <KibanaDashboard />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;

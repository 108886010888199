import { configureStore } from "@reduxjs/toolkit";
import delegaciaSlice from "./delegacia-slice";
import headerSlice from "./header-slice";
import orgaoSlice from "./orgao-slice";
import procedimentoSlice from "./procedimento-slice";
import eventoHistoricoSlice from "./evento-historico-slice";
import remessaPcerjSlice from "./remessa-pcerj-slice";
import importaSlice from "./importa-slice";

const store = configureStore({
  reducer: {
    header: headerSlice.reducer,
    orgao: orgaoSlice.reducer,
    delegacia: delegaciaSlice.reducer,
    procedimento: procedimentoSlice.reducer,
    eventoHistorico: eventoHistoricoSlice.reducer,
    remessaPcerj: remessaPcerjSlice.reducer,
    importa: importaSlice.reducer,
  },
});

export default store;

let redirectUri = window.location.protocol + "//" + window.location.host;
if (window.location.host === "localhost") {
  redirectUri = redirectUri + ":" + window.location.port;
}

export const msalConfig = {
  auth: {
    clientId: "b77e5880-a866-478b-882a-5e5883c3fe1a",
    // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority: "https://login.microsoftonline.com/c9e47896-e07f-44ce-86cd-2ef784cda1db",
    // desenv = https://integra-labs-frontend-integra-dev.apps.ocpn.mprj.mp.br
    redirectUri: redirectUri,
  },
  cache: {
    // localStorage | sessionStorage
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};

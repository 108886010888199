import { Grid, LinearProgress, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import procedimentosService from "./service/procedimentos-service";
import store from "src/store";
import ProcedimentoCard from "./ProcedimentoCard";

const ProcedimentoGrid = () => {
  const { procedimentos } = useLoaderData();

  return (
    <>
      <Suspense
        fallback={
          <>
            <br />
            <LinearProgress />
          </>
        }
      >
        <Await resolve={procedimentos}>
          {(result) => (
            <>
              <Typography variant="body2" sx={{ pt: 2 }}>
                {result.length} Resultados de&nbsp;
                {result.length > 50 ? "mais de 50..." : result.length} Total
              </Typography>
              <Fade in={true}>
                <Grid container spacing={{ xs: 1, md: 1.5 }} sx={{ pt: 2 }}>
                  {result &&
                    result.map((procedimento) => {
                      return (
                        <Grid
                          item
                          key={procedimento._id}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={12 / 5}
                        >
                          <ProcedimentoCard procedimento={procedimento} />
                        </Grid>
                      );
                    })}
                </Grid>
              </Fade>
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export default ProcedimentoGrid;

export function procedimentosLoader({ request, params }) {
  const searchParams = new URL(request.url).searchParams;
  const term =
    searchParams.get("term") || store.getState().procedimento.term || "";
  const page =
    searchParams.get("page") || store.getState().procedimento.page || 0;
  const categoria =
    searchParams.get("categoria") ||
    store.getState().procedimento.categoria ||
    "0";
  const situacao =
    searchParams.get("situacao") ||
    store.getState().procedimento.situacao ||
    "0";
  return defer({
    procedimentos: procedimentosService.findByFilter(
      term,
      categoria,
      situacao,
      page
    ),
  });
}

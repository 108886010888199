import { Avatar, Card, CardContent, CardHeader, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface Props {
  orgao: any;
}

function OrgaoCard({ orgao }: Props) {
  return (
    <Card
      sx={{
        minHeight: 200,
        maxHeight: 220,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        ":hover": {
          boxShadow: 15,
        },
        boxShadow: 2,
      }}
    >
      <CardHeader
        sx={{ pt: 1.5, pb: 1 }}
        avatar={
          <Tooltip title={orgao && orgao.ativo ? "Ativo" : "Inativo"}>
            <Avatar aria-label="recipe" alt="asfasgs" sx={{ width: 24, height: 24, bgcolor: "secondary.dark" }}>
              {orgao && orgao.ativo ? "A" : "I"}
            </Avatar>
          </Tooltip>
        }
        action={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Typography variant="body2" sx={{ paddingBottom: 0 }}>
            <b>{orgao.tipoOrgao}</b>
          </Typography>
        }
        subheader={
          <Typography variant="body2" sx={{ color: "rgb(136, 14, 79)" }}>
            <b>{orgao.codigo}</b>
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {orgao.nome}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default OrgaoCard;
